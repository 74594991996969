import * as firebase from "firebase/app";
import "firebase/messaging";

export const initializeFirebase = () => {
    firebase.initializeApp({
        apiKey: "AIzaSyDXZLy3giyuqvNAyEf4bgE72hLFMNR0RWY",
        authDomain: "gnc-cash.firebaseapp.com",
        databaseURL: "https://gnc-cash.firebaseio.com",
        projectId: "gnc-cash",
        storageBucket: "gnc-cash.appspot.com",
        messagingSenderId: "709366004491",
        appId: "1:709366004491:web:9073137268b4f69257c00e"
    });

    navigator.serviceWorker
        .register('/firebase-messaging-sw.js')
        .then((registration) => {
            firebase.messaging().useServiceWorker(registration);
        });
};

export const askForPermissionToReceiveNotifications = async () => {
  try {
    const messaging = firebase.messaging();
    await messaging.requestPermission();
    const token = await messaging.getToken();
    console.log('token del usuario:', token);
    localStorage.setItem("notification-token", token);

    return token;
  } catch (error) {
    console.error(error);
  }
};