import React, { Component } from 'react';
import { connect } from "react-redux";
import Drawer, {DrawerContent, DrawerHeader} from '@material/react-drawer';
import TopAppBar, {TopAppBarFixedAdjust, TopAppBarIcon, TopAppBarRow, TopAppBarSection, TopAppBarTitle} from '@material/react-top-app-bar';
import MaterialIcon from '@material/react-material-icon';
import AppMenuList from './AppMenuList';
import AppMenuFooter from './AppMenuFooter';
import Home from '../views/Home';
import Deals from '../views/Deals';
import DealsPlus from '../views/DealsPlus';
import Faqs from '../views/Faqs';
import Barcodes from "../views/Barcodes";
import History from "../views/History";
import Account from "../views/Account";
import Stores from "../views/Stores";
import Plan from "../views/Plan";
import Redeem from "../views/Redeem";
import {
  Route
} from "react-router-dom";
import mygnclogo from '../assets/mygnclogo.png';

function mapStateToProps(state) {
    return state.navigationReducer;
}

class AppDrawer extends Component{
    state = {
        open: false,
    };

    render() {
        return (
            <div>
                <Drawer
                    modal
                    open={this.state.open}
                    onClose={() => this.setState({open: false})}
                >
                    <DrawerHeader className="textCenter">
                        <img className="gncLogoDrawer" src={mygnclogo} alt="MY GNC"/>
                    </DrawerHeader>
                    <DrawerContent>
                        <AppMenuList closeDrawer={() => this.setState({open: false})}/>
                        <AppMenuFooter/>
                    </DrawerContent>
                </Drawer>
                <div>
                    <TopAppBar fixed={true} dense={true}>
                        <TopAppBarRow>
                            <TopAppBarSection align='start'>
                                <TopAppBarIcon navIcon tabIndex={0}>
                                    <MaterialIcon hasRipple icon='menu' onClick={() => this.setState({open: !this.state.open})}/>
                                </TopAppBarIcon>
                                <TopAppBarTitle><div dangerouslySetInnerHTML={{__html: this.props.appTitle}}/></TopAppBarTitle>
                            </TopAppBarSection>
                        </TopAppBarRow>
                    </TopAppBar>
                    <TopAppBarFixedAdjust>
                        <div>
                            <Route path="/app/home" component={Home} />
                            <Route path="/app/deals" component={Deals} />
                            <Route path="/app/faqs" component={Faqs} />
                            <Route path="/app/barcode" component={Barcodes} />
                            <Route path="/app/history" component={History} />
                            <Route path="/app/stores" component={Stores} />
                            <Route path="/app/plan" component={Plan} />
                            <Route path="/app/redeem" component={Redeem} />
                            <Route path="/app/dealsplus" component={DealsPlus} />
                            <Route path="/app/account" component={Account} />
                        </div>
                    </TopAppBarFixedAdjust>
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps)(AppDrawer);