import React, { Component } from 'react';
import { connect } from "react-redux";
import {bindActionCreators} from "redux";
import { withRouter } from "react-router-dom";

import List, {ListItem, ListItemText, ListItemGraphic} from '@material/react-list';
import MaterialIcon from '@material/react-material-icon';
import {authActions} from "../actions/authActions";

class AppMenuList extends Component{
    goToHome = () => {
        this.props.closeDrawer();
        this.props.history.push('/app/home');
    };
    goToBarcode = () => {
        this.props.closeDrawer();
        this.props.history.push('/app/barcode');
    };
    goToHistory = () => {
        this.props.closeDrawer();
        this.props.history.push('/app/history');
    };
    goToStores = () => {
        this.props.closeDrawer();
        this.props.history.push('/app/stores');
    };
    goToDeals = () => {
        this.props.closeDrawer();
        this.props.history.push('/app/deals');
    };
    goToDealsPlus = () => {
        this.props.closeDrawer();
        this.props.history.push('/app/dealsplus');
    };
    goToAccount = () => {
        this.props.closeDrawer();
        this.props.history.push('/app/account');
    };
    goToFaqs = () => {
        this.props.closeDrawer();
        this.props.history.push('/app/faqs');
    };
    goToPlan = () => {
        this.props.closeDrawer();
        this.props.history.push('/app/plan');
    };
    render() {
        return (
            <List singleSelection selectedIndex={this.props.menuSelectedIndex}>
                <ListItem onClick={this.goToHome}>
                    <ListItemGraphic graphic={<MaterialIcon icon='home'/>} />
                    <ListItemText primaryText='Inicio' />
                </ListItem>
                <ListItem onClick={this.goToAccount}>
                    <ListItemGraphic graphic={<MaterialIcon icon='account_circle'/>} />
                    <ListItemText primaryText='Mi Cuenta' />
                </ListItem>
                <ListItem onClick={this.goToDeals}>
                    <ListItemGraphic graphic={<MaterialIcon icon='shop_two'/>} />
                    <ListItemText primaryText='Promociones' />
                </ListItem>
                <ListItem onClick={this.goToDealsPlus}>
                    <ListItemGraphic graphic={<MaterialIcon icon='ads_click'/>} />
                    <ListItemText primaryText='Promociones Adicionales' />
                </ListItem>
                <ListItem onClick={this.goToStores}>
                    <ListItemGraphic graphic={<MaterialIcon icon='map'/>} />
                    <ListItemText primaryText='Tiendas' />
                </ListItem>
                <ListItem onClick={this.goToHistory}>
                    <ListItemGraphic graphic={<MaterialIcon icon='history'/>} />
                    <ListItemText primaryText='Historial' />
                </ListItem>
                <ListItem onClick={this.goToBarcode}>
                    <ListItemGraphic className="fas fa-barcode iconBarcode" graphic={<i aria-hidden="true"></i>} />
                    <ListItemText primaryText='Mi BENEfit ID' />
                </ListItem>
                <ListItem onClick={this.goToFaqs}>
                    <ListItemGraphic graphic={<MaterialIcon icon='help'/>} />
                    <ListItemText primaryText='Preguntas Frecuentes' />
                </ListItem>
                <ListItem onClick={this.goToPlan}>
                    <ListItemGraphic graphic={<MaterialIcon icon='account_balance_wallet'/>} />
                    <ListItemText primaryText='Mi Programa' />
                </ListItem>
                <a isLink={true} href='https://gnc.com.gt/?utm_source=benefitapp&utm_medium=menu&utm_campaign=menu' target='_blank' rel="noopener noreferrer" className='fontZero'>
                    <ListItem >
                        <ListItemGraphic graphic={<MaterialIcon icon='shopping_cart'/>} />
                        <ListItemText primaryText='Comprar Aquí' />
                    </ListItem>
                </a>
                <ListItem onClick={this.props.auth.logout}>
                    <ListItemGraphic graphic={<MaterialIcon icon='input'/>} />
                    <ListItemText primaryText='Cerrar Sesión' />
                </ListItem>
                {/*<ListItem tag="a" href="/app/cupons">}
                {/*    <ListItemGraphic graphic={<MaterialIcon icon='shopping_cart'/>} />*/}
                {/*    <ListItemText primaryText='Cupones eCommerce' />*/}
                {/*</ListItem>*/}
            </List>
        );
    }
}
function mapStateToProps(state) {
    return state.navigationReducer;
}
function mapDispatchToProps(dispatch) {
    return {
        auth: bindActionCreators(authActions, dispatch),
    };
}

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(AppMenuList));