import React, { Component } from 'react';

import SwipeableViews from 'react-swipeable-views';
import Button from '@material/react-button';
import { usePapaParse } from 'react-papaparse';
// import mygnclogo from '../assets/mygnclogo.png';

class Onboarding extends Component{
    state = {
        slideIndex: 0,
        imgUrls: {
            0: {0:0, 1:'ImgURL'},
        }
    };
    componentDidMount() {
        const { readRemoteFile } = usePapaParse();

        readRemoteFile('https://docs.google.com/spreadsheets/d/e/2PACX-1vSgutdIK8HzC7P_LtfcGwrrO68I4g74L5UdkEk-kujjsf3gHs_MAIG3pBAB2Xeby12uKpqLf3_flr4R/pub?output=csv', {
            complete: (results) => {
                console.log('---------------------------');
                console.log('Results:', results.data);
                console.log('---------------------------');
                this.setState({
                    imgUrls: results.data
                })
            },
        });
    }

    handleSlideIndexUpdate = (slideIndex) => this.setState({slideIndex});
    changeSlide(index) {
        this.setState({
            slideIndex: index
        })
    }

    goToLogin = () => {
        this.props.history.push('/login')
    };
    goToRegister = () => {
        this.props.history.push('/register')
    };
    render() {
        const { imgUrls } = this.state;
        let onboarding1,onboarding2,onboarding3,onboarding4 = {backgroundImage : "url('')"};
        if(imgUrls.length > 1){
            onboarding1 = { backgroundImage : "url('"+ imgUrls[1][1] +"')"};
            onboarding2 = { backgroundImage : "url('"+ imgUrls[2][1] +"')"};
            onboarding3 = { backgroundImage : "url('"+ imgUrls[3][1] +"')"};
            onboarding4 = { backgroundImage : "url('"+ imgUrls[4][1] +"')"};
        }
        return (
            <div>
                <div className="startButtonContainer">
                    <div className="stepperContainer">
                        <div className="mdl-card mdl-shadow--2dp">
                            <div className="mdl-card__supporting-text">
                                <div className="mdl-stepper-horizontal-alternative">
                                    <div className={0 <= this.state.slideIndex ? 'mdl-stepper-step active-step': 'mdl-stepper-step'} onClick={this.changeSlide.bind(this, 0)}>
                                        <div className="mdl-stepper-circle"><span>1</span></div>
                                        <div className="mdl-stepper-bar-left"></div>
                                        <div className="mdl-stepper-bar-right"></div>
                                    </div>
                                    <div className={1 <= this.state.slideIndex ? 'mdl-stepper-step active-step': 'mdl-stepper-step'} onClick={this.changeSlide.bind(this, 1)}>
                                        <div className="mdl-stepper-circle"><span>2</span></div>
                                        <div className="mdl-stepper-bar-left"></div>
                                        <div className="mdl-stepper-bar-right"></div>
                                    </div>
                                    <div className={2 <= this.state.slideIndex ? 'mdl-stepper-step active-step': 'mdl-stepper-step'} onClick={this.changeSlide.bind(this, 2)}>
                                        <div className="mdl-stepper-circle"><span>3</span></div>
                                        <div className="mdl-stepper-bar-left"></div>
                                        <div className="mdl-stepper-bar-right"></div>
                                    </div>
                                    <div className={3 <= this.state.slideIndex ? 'mdl-stepper-step active-step': 'mdl-stepper-step'} onClick={this.changeSlide.bind(this, 3)}>
                                        <div className="mdl-stepper-circle"><span>4</span></div>
                                        <div className="mdl-stepper-bar-left"></div>
                                        <div className="mdl-stepper-bar-right"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Button raised onClick={this.goToRegister} className="btnStart btnFirst">
                        crear cuenta
                    </Button>
                    <Button raised onClick={this.goToLogin} className="btnStart">
                        ingresar
                    </Button>
                </div>
                <SwipeableViews
                    index={this.state.slideIndex}
                    onChangeIndex={this.handleSlideIndexUpdate}
                >
                    <div className='slide slide1 allSwipe' style={onboarding1}>
                    </div>
                    <div className='slide slide2 allSwipe' style={onboarding2}>
                    </div>
                    <div className='slide slide3 allSwipe' style={onboarding3}>
                    </div>
                    <div className='slide slide4 allSwipe' style={onboarding4}>
                    </div>
                </SwipeableViews>
            </div>
        );
    }
}

export default Onboarding;