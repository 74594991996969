import axios                    from 'axios'
import axiosRetry               from 'axios-retry';

import {authHeader}             from "../helpers/authHeader"
import {history}                from '../helpers/history'

import {authActions}  from "../actions/authActions"

const APP_API_URL='https://benefitapp.vitamas.com.gt:9000/api';

/**
 * Create an Axios Client with defaults
 */
const client = axios.create({
    baseURL:  APP_API_URL
});

axiosRetry(client, { retries: 3 });

client.interceptors.request.use(function(config) {

    config.headers.Authorization = `Bearer ${authHeader()}`;
    return config;

}, function(err) {

    return Promise.reject(err);

});


/**
 * Request Wrapper with default success/error actions
 */
const requestsHandler = function(options) {
    const onSuccess = function(response) {
        //console.log(response);
        return response.data;
    };

    const onError = function(error) {
        //console.log(error);
        if (error.response) {

            // Request was made but server responded with something
            // other than 2xx
            // console.error('Status:',  error.response.status);
            // console.error('Data:',    error.response.data);
            // console.error('Headers:', error.response.headers);

            if (error.response.status === 401) {
                // auto logout if 401 response returned from api
                authActions.logout();
                history.push('/login')
                // location.reload(true);
            }

        } else {

            // Something else happened while setting up the request
            // triggered the error
            //console.error('Error Message:', error.message);
        }

        return Promise.reject(error.response || error.message);
    };

    return client(options)
        .then(onSuccess)
        .catch(onError);
};

export default requestsHandler;