import React, { Component } from 'react';
import {bindActionCreators} from "redux";
import { connect } from "react-redux";
import {navigationActions} from "../actions/navigationActions";
import {userActions} from "../actions/userActions";
import {alertActions} from "../actions/alertActions";

import Tab from '@material/react-tab';
import TabBar from '@material/react-tab-bar';
import MaterialIcon from "@material/react-material-icon";
import Button from '@material/react-button';
import TextField, {HelperText, Input} from '@material/react-text-field';
import Select, {Option} from '@material/react-select';
// import Checkbox from '@material/react-checkbox';
import SwipeableViews from 'react-swipeable-views';
import MaskedInput from 'react-text-mask';
import HomeProfileCard from "../components/HomeProfileCard";
import MomentUtils from '@date-io/moment';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import gnccashwhite from '../assets/gnccashwhite.png';


class Account extends Component{
    state = {
        menuIndex: 1,
        appTitle: 'Mi Cuenta',
        slideIndex: 0,
        errors: {},
        pass0: '',
        pass1: '',
        pass2: '',
        passerrors: {
            pass0: 'Debes completar este campo.',
            pass1: 'Debes completar este campo.',
            pass2: 'Debes completar este campo.',
        },
    };

    constructor(props) {
        super(props);
        this.props.navigation.setAppTitle(this.state.appTitle);
        this.props.navigation.setMenuIndex(this.state.menuIndex);
    }

    handleSlideIndexUpdate = (slideIndex) => this.setState({slideIndex});

    componentDidUpdate = (prevProps, prevState) => {
        if(this.props.userData !== null && prevProps.userData == null) {
            this.props.user.getData();
        }
    };

    handleDateChange = date => {
        let new_state;
        let new_date = new Date(date);
        const today = new Date();
        if(!this.isValidDate(new_date) || new_date>today){
            let errors = Object.assign({},this.state.errors, {FECHA_NACIMIENTO:'Debe ingresar una Fecha de Nacimiento válida.'});
            new_state = Object.assign({}, this.state, {errors: errors} );
        } else {
            delete this.state.errors.FECHA_NACIMIENTO;
            new_state = Object.assign({}, this.state);
        }
        this.setState(new_state);
        this.props.user.handleInput({ FECHA_NACIMIENTO: date });
    };

    isValidDate(d) {
        return d instanceof Date && !isNaN(d);
    }

    handleInput = event =>  {
        const { name, value, required } = event.target;
        let new_state;
        let msg = event.target.getAttribute('errormsg')?event.target.getAttribute('errormsg'):'Debes completar este campo.';
        if(!value && required) {
            let errors = Object.assign({},this.state.errors, {[name]:msg});
            new_state = Object.assign({}, this.state, {errors: errors} );
        } else {
            delete this.state.errors[name];
            new_state = Object.assign({}, this.state);
        }

        this.setState(new_state);
        this.props.user.handleInput({[name]:value});
    };

    handleEmailInput = event => {
        const { value } = event.target;
        let new_state;
        if(!value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
            let errors = Object.assign({},this.state.errors, {EMAIL:'Debes colocar un mail válido.'});
            new_state = Object.assign({}, this.state, {errors: errors} );
        } else {
            delete this.state.errors.EMAIL;
            new_state = Object.assign({}, this.state);
        }
        this.setState(new_state);
        this.props.user.handleInput({EMAIL:value});
    };

    handleDirInput = event => {
        const { value } = event.target;
        this.props.user.handleInput({ REGION1: value });
    };

    handleSubmit = () => {
        if(this.state.errors.length > 0){
            this.showErrors();
        }else{
            this.props.user.edit(this.props.userData);
        }
    };

    showErrors = () => {
        let msg = "";
        Object.keys(this.state.errors).map(key =>
            msg += this.state.errors[key]+' <br> '
        );
        this.props.alertActions.error(msg);
    };

    handlePassInput = event =>  {
        const { name, value, required } = event.target;
        let new_state;
        if(!value && required) {
            let passerrors = Object.assign({},this.state.passerrors, {[name]:'Debes completar este campo.'});
            new_state = Object.assign({}, this.state, {passerrors: passerrors} );
        } else {
            delete this.state.passerrors[name];
            new_state = Object.assign({}, this.state);
        }
        this.setState(new_state);
        this.setState({[name]:value});
    };

    handlePassSubmit = (e) => {
        e.preventDefault();
        let valid = false;
        let user = JSON.parse(localStorage.getItem('user'));
        if(this.state.pass0 === user.user.PASSWORD){
            valid = true;
        }else{
            this.props.alertActions.error('La Contraseña Actual no coincide.');
            return false;
        }
        if(valid && (this.state.pass1 === this.state.pass2)){
            this.props.user.changePass(this.state.pass1);
            this.setState({
                pass0: '',
                pass1: '',
                pass2: '',
                passerrors: {
                    pass0: 'Debes completar este campo.',
                    pass1: 'Debes completar este campo.',
                    pass2: 'Debes completar este campo.',
                },
            });
        }else {
            this.props.alertActions.error('Los campos de Nueva Contraseña no coinciden');
            return false;
        }
    };

    render() {
        return (
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <div>
                    <div className='tabContainer'>
                        <TabBar
                          activeIndex={this.state.slideIndex}
                          handleActiveIndexUpdate={this.handleSlideIndexUpdate}
                        >
                            <Tab>
                                <span className="mdc-tab__content">
                                    <MaterialIcon className='mdc-tab__icon' icon='fingerprint'/>
                                    <span className="mdc-tab__text-label"> 1</span>
                                </span>
                            </Tab>
                            <Tab>
                                <span className="mdc-tab__content">
                                    <MaterialIcon className='mdc-tab__icon' icon='person'/>
                                    <span className="mdc-tab__text-label"> 2</span>
                                </span>
                            </Tab>
                            <Tab>
                                <span className="mdc-tab__content">
                                    <MaterialIcon className='mdc-tab__icon' icon='sentiment_satisfied_alt'/>
                                    <span className="mdc-tab__text-label"> 3</span>
                                </span>
                            </Tab>
                            <Tab>
                                <span className="mdc-tab__content">
                                    <MaterialIcon className='mdc-tab__icon' icon='vpn_key'/>
                                </span>
                            </Tab>
                        </TabBar>
                    </div>
                    <form id='account_form'>
                        <SwipeableViews
                            index={this.state.slideIndex}
                            onChangeIndex={this.handleSlideIndexUpdate}
                            className='margTop'
                        >
                            <div className='slide mainContainer'>
                                {/*<TextField*/}
                                {/*    className='fullWidth'*/}
                                {/*    label='Nombre'*/}
                                {/*    helperText={<HelperText>{this.state.errors.PRIMER_NOMBRE ? this.state.errors.PRIMER_NOMBRE : ''}</HelperText>}*/}
                                {/*><Input*/}
                                {/*    disabled*/}
                                {/*    required*/}
                                {/*    id="PRIMER_NOMBRE"*/}
                                {/*    name="PRIMER_NOMBRE"*/}
                                {/*    // className={classes.textField}*/}
                                {/*    onChange={this.handleInput}*/}
                                {/*    value={ this.props.userData.PRIMER_NOMBRE != null ? this.props.userData.PRIMER_NOMBRE : ''}*/}
                                {/*    error={!!this.state.errors.PRIMER_NOMBRE}*/}
                                {/*/>*/}
                                {/*</TextField>*/}
                                <TextField
                                    className='fullWidth'
                                    label='Nombre Completo'
                                    helperText={<HelperText>{this.state.errors.NOMBRE_COMPLETO ? this.state.errors.NOMBRE_COMPLETO : ''}</HelperText>}
                                ><Input
                                    required
                                    errormsg="Debe ingresar un Nombre válido."
                                    maxLength="100"
                                    id="NOMBRE_COMPLETO"
                                    name="NOMBRE_COMPLETO"
                                    // className={classes.textField}
                                    onChange={this.handleInput}
                                    value={ this.props.userData.NOMBRE_COMPLETO != null ? this.props.userData.NOMBRE_COMPLETO : ''}
                                    //error={!!this.state.errors.NOMBRE_COMPLETO}
                                />
                                </TextField>
                                <Input type="hidden" name="TIPOIDENTIFICACION" value={ this.props.userData.TIPOIDENTIFICACION != null ? this.props.userData.TIPOIDENTIFICACION : 'D'}/>
                                <TextField
                                    className='fullWidth'
                                    label={ this.props.userData.TIPOIDENTIFICACION != null && this.props.userData.TIPOIDENTIFICACION === "D" ? "DPI" : "Pasaporte"}
                                    helperText={<HelperText>{this.state.errors.DPI ? this.state.errors.DPI : ''}</HelperText>}
                                ><Input
                                    disabled
                                    id="DPI"
                                    name="DPI"
                                    // className={classes.textField}
                                    onChange={this.handleInput}
                                    value={ this.props.userData.DPI != null ? this.props.userData.DPI : ''}
                                    //error={!!this.state.errors.DPI}
                                />
                                </TextField>
                                <TextField
                                    className='fullWidth'
                                    label='Email'
                                    helperText={<HelperText>{this.state.errors.EMAIL ? this.state.errors.EMAIL : ''}</HelperText>}
                                ><Input
                                    required
                                    maxLength="55"
                                    id="EMAIL"
                                    name="EMAIL"
                                    // className={classes.textField}
                                    onChange={this.handleEmailInput}
                                    value={ this.props.userData.EMAIL != null ? this.props.userData.EMAIL : ''}
                                    //error={!!this.state.errors.EMAIL}
                                />
                                </TextField>
                                <div className="mdc-text-field fullWidth">
                                    <MaskedInput
                                        className="mdc-text-field__input"
                                        mask={[/\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                                        required
                                        errormsg="Debe ingresar un Celular válido."
                                        maxLength="100"
                                        id="TELEFONO"
                                        name="TELEFONO"
                                        // className={classes.textField}
                                        onChange={this.handleInput}
                                        value={ this.props.userData.TELEFONO != null ? this.props.userData.TELEFONO : ''}
                                    />
                                    <label className="mdc-floating-label mdc-floating-label--float-above" htmlFor="TELEFONO">Celular</label>
                                    <div className="mdc-line-ripple"></div>
                                </div>
                                <div className="mdc-text-field-helper-line">
                                    <p className="mdc-text-field-helper-text" aria-hidden="false">{this.state.errors.TELEFONO ? this.state.errors.TELEFONO : ''}</p>
                                </div>
                                {/*<TextField*/}
                                {/*    className='fullWidth'*/}
                                {/*    label='Celular'*/}
                                {/*    helperText={<HelperText>{this.state.errors.TELEFONO ? this.state.errors.TELEFONO : ''}</HelperText>}*/}
                                {/*>*/}
                                {/*    <Input*/}
                                {/*    required*/}
                                {/*    maxLength="100"*/}
                                {/*    id="TELEFONO"*/}
                                {/*    name="TELEFONO"*/}
                                {/*    // className={classes.textField}*/}
                                {/*    onChange={this.handleInput}*/}
                                {/*    value={ this.props.userData.TELEFONO != null ? this.props.userData.TELEFONO : ''}*/}
                                {/*    //error={!!this.state.errors.TELEFONO}*/}
                                {/*/>*/}
                                {/*</TextField>*/}
                                <TextField
                                    className='fullWidth'
                                    label='NIT'
                                    helperText={<HelperText>{this.state.errors.NIT ? this.state.errors.NIT : ''}</HelperText>}
                                ><Input
                                    maxLength="30"
                                    id="NIT"
                                    name="NIT"
                                    // className={classes.textField}
                                    onChange={this.handleInput}
                                    value={ this.props.userData.NIT != null ? this.props.userData.NIT : ''}
                                    //error={!!this.state.errors.NIT}
                                />
                                </TextField>
                            </div>
                            <div className='slide mainContainer'>
                                <KeyboardDatePicker
                                    disableFuture
                                    allowKeyboardControl={false}
                                    errormsg="Debe ingresar una Fecha de Nacimiento válida."
                                    name="FECHA_NACIMIENTO"
                                    openTo="year"
                                    format="DD/MM/YYYY"
                                    label="Fecha de Nacimiento"
                                    views={["year", "month", "date"]}
                                    className='fullWidth'
                                    //className={classes.textField}
                                    inputVariant="filled"
                                    value={this.props.userData.FECHA_NACIMIENTO != null ? this.props.userData.FECHA_NACIMIENTO : null}
                                    onChange={this.handleDateChange}
                                    //error={!!this.state.errors.FECHA_NACIMIENTO}
                                    helperText={this.state.errors.FECHA_NACIMIENTO ? this.state.errors.FECHA_NACIMIENTO : ''}
                                />
                                <HelperText>{this.state.errors.FECHA_NACIMIENTO ? this.state.errors.FECHA_NACIMIENTO : ''}</HelperText>
                                <Select
                                    label='Género'
                                    id="GENERO"
                                    name="GENERO"
                                    className='fullWidth'
                                    // className={classes.textField}
                                    onChange={this.handleInput}
                                    value={ this.props.userData.GENERO != null ? ""+this.props.userData.GENERO : ''}
                                    //error={!!this.state.errors.GENERO}
                                    helperText={<HelperText>{this.state.errors.GENERO ? this.state.errors.GENERO : ''}</HelperText>}
                                >
                                    <Option value=''></Option>
                                    <Option value='F'>Femenino</Option>
                                    <Option value='M'>Masculino</Option>
                                </Select>
                                <Select
                                    label='Departamento'
                                    id="DEPARTAMENTO_ID"
                                    name="DEPARTAMENTO_ID"
                                    className='fullWidth'
                                    // className={classes.textField}
                                    onChange={this.handleDirInput}
                                    value={ this.props.userData.REGION1 != null && this.props.userData.REGION1 !== '' ? (parseInt(this.props.userData.REGION1) < 20100 ? ""+this.props.userData.REGION1 : '20011' ): ''}
                                    //error={!!this.state.errors.DEPARTAMENTO}
                                    helperText={<HelperText>{this.state.errors.REGION1 ? this.state.errors.REGION1 : ''}</HelperText>}
                                >
                                    <Option value=''></Option>
                                    <Option value='20012'>Huehuetenango</Option>
                                    <Option value='20013'>Izabal</Option>
                                    <Option value='20014'>Jalapa</Option>
                                    <Option value='20015'>Jutiapa</Option>
                                    <Option value='20016'>Petén</Option>
                                    <Option value='20023'>Suchitepéquez</Option>
                                    <Option value='20024'>Totonicapán</Option>
                                    <Option value='20025'>Zacapa</Option>
                                    <Option value='20017'>Quetzaltenango</Option>
                                    <Option value='20018'>Retalhuleu</Option>
                                    <Option value='20019'>Sacatepéquez</Option>
                                    <Option value='20020'>San Marcos</Option>
                                    <Option value='20021'>Santa Rosa</Option>
                                    <Option value='20022'>Sololá</Option>
                                    <Option value='20004'>Alta Verapaz</Option>
                                    <Option value='20005'>Baja Verapaz</Option>
                                    <Option value='20006'>Chimaltenango</Option>
                                    <Option value='20007'>Chiquimula</Option>
                                    <Option value='20008'>El Progreso</Option>
                                    <Option value='20009'>El Quiché</Option>
                                    <Option value='20010'>Escuintla</Option>
                                    <Option value='20011'>Guatemala</Option>
                                </Select>
                                <Select
                                    disabled={!(parseInt(this.props.userData.REGION1) === 20011 || parseInt(this.props.userData.REGION1) > 20100) }
                                    label='Municipio'
                                    id="MUNICIPIO_ID"
                                    name="MUNICIPIO_ID"
                                    className='fullWidth'
                                    // className={classes.textField}
                                    onChange={this.handleDirInput}
                                    value={ this.props.userData.REGION1 != null && parseInt(this.props.userData.REGION1) > 20100 ?
                                            (parseInt(this.props.userData.REGION1) < 20200 ?
                                                ""+this.props.userData.REGION1
                                                : '20119'
                                            )
                                          : ''}
                                    //error={!!this.state.errors.MUNICIPIO}
                                    helperText={<HelperText>{this.state.errors.REGION1 ? this.state.errors.REGION1 : ''}</HelperText>}
                                >
                                    <Option value=''></Option>
                                    <Option value='20119'>Guatemala</Option>
                                    <Option value='20120'>Santa Catarina Pinula</Option>
                                    <Option value='20121'>San José Pinula</Option>
                                    <Option value='20122'>San José Del Golfo</Option>
                                    <Option value='20123'>Palencia</Option>
                                    <Option value='20124'>Chinautla</Option>
                                    <Option value='20125'>San Pedro Ayampuc</Option>
                                    <Option value='20126'>Mixco</Option>
                                    <Option value='20127'>San Pedro Sacatepéquez</Option>
                                    <Option value='20128'>San Juan Sacatepéquez</Option>
                                    <Option value='20129'>San Raymundo</Option>
                                    <Option value='20130'>Chuarrancho</Option>
                                    <Option value='20131'>Fraijanes</Option>
                                    <Option value='20132'>Amatitlán</Option>
                                    <Option value='20133'>Villa Nueva</Option>
                                    <Option value='20134'>Villa Canales</Option>
                                    <Option value='20135'>San Miguel Petapa</Option>
                                </Select>
                                <Select
                                    disabled={!(parseInt(this.props.userData.REGION1) === 20119 || parseInt(this.props.userData.REGION1) > 20300) }
                                    label='Zona'
                                    id="ZONA_ID"
                                    name="ZONA_ID"
                                    className='fullWidth'
                                    // className={classes.textField}
                                    onChange={this.handleDirInput}
                                    value={ this.props.userData.REGION1 != null &&  parseInt(this.props.userData.REGION1) > 20300 ?
                                            ""+this.props.userData.REGION1
                                          : ''}
                                    //error={!!this.state.errors.ZONA}
                                    helperText={<HelperText>{this.state.errors.ZONA ? this.state.errors.ZONA : ''}</HelperText>}
                                >
                                    <Option value=''></Option>
                                    <Option value='20362'>Zona 1</Option>
                                    <Option value='20363'>Zona 2</Option>
                                    <Option value='20364'>Zona 3</Option>
                                    <Option value='20365'>Zona 4</Option>
                                    <Option value='20366'>Zona 5</Option>
                                    <Option value='20367'>Zona 6</Option>
                                    <Option value='20368'>Zona 7</Option>
                                    <Option value='20369'>Zona 8</Option>
                                    <Option value='20370'>Zona 9</Option>
                                    <Option value='20371'>Zona 10</Option>
                                    <Option value='20372'>Zona 11</Option>
                                    <Option value='20373'>Zona 12</Option>
                                    <Option value='20374'>Zona 13</Option>
                                    <Option value='20375'>Zona 14</Option>
                                    <Option value='20376'>Zona 15</Option>
                                    <Option value='20377'>Zona 16</Option>
                                    <Option value='20378'>Zona 17</Option>
                                    <Option value='20379'>Zona 18</Option>
                                    <Option value='20380'>Zona 19</Option>
                                    <Option value='20381'>Zona 21</Option>
                                </Select>
                                <TextField
                                    className='fullWidth'
                                    label='Dirección para entrega a Domicilio'
                                    helperText={<HelperText>{this.state.errors.DIRECCION ? this.state.errors.DIRECCION : ''}</HelperText>}
                                ><Input
                                    id="DIRECCION"
                                    name="DIRECCION"
                                    maxLength="200"
                                    // className={classes.textField}
                                    onChange={this.handleInput}
                                    value={ this.props.userData.DIRECCION != null ? this.props.userData.DIRECCION : ''}
                                    //error={!!this.state.errors.DIRECCION}
                                />
                                </TextField>
                            </div>
                            <div className='slide mainContainer'>
                                <HomeProfileCard
                                    img={gnccashwhite}
                                    bigText="Avatar de Perfil"/>
                                <Select
                                    label='Estado Civil'
                                    id="ESTADO_CIVIL"
                                    name="ESTADO_CIVIL"
                                    className='fullWidth'
                                    // className={classes.textField}
                                    onChange={this.handleInput}
                                    value={ this.props.userData.ESTADO_CIVIL != null ? ""+this.props.userData.ESTADO_CIVIL : ''}
                                    //error={!!this.state.errors.ESTADO_CIVIL}
                                    helperText={<HelperText>{this.state.errors.ESTADO_CIVIL ? this.state.errors.ESTADO_CIVIL : ''}</HelperText>}
                                >
                                    <Option value=''></Option>
                                    <Option value='Casado'>Casado</Option>
                                    <Option value='Soltero'>Soltero</Option>
                                </Select>
                                <Select
                                    label='Hijos'
                                    id="HIJOS"
                                    name="HIJOS"
                                    className='fullWidth'
                                    // className={classes.textField}
                                    onChange={this.handleInput}
                                    value={ this.props.userData.HIJOS != null ? ""+this.props.userData.HIJOS : ''}
                                    //error={!!this.state.errors.HIJOS}
                                    helperText={<HelperText>{this.state.errors.HIJOS ? this.state.errors.HIJOS : ''}</HelperText>}
                                >
                                    <Option value=''></Option>
                                    <Option value='0'>0</Option>
                                    <Option value='1'>1</Option>
                                    <Option value='2'>2</Option>
                                    <Option value='3'>3</Option>
                                    <Option value='4'>4</Option>
                                    <Option value='5'>5</Option>
                                    <Option value='6'>6</Option>
                                    <Option value='7'>7</Option>
                                    <Option value='8'>8</Option>
                                    <Option value='9'>9</Option>
                                    <Option value='10'>10</Option>
                                </Select>
                                {/*<div className="mdc-form-field">*/}
                                {/*    <Checkbox*/}
                                {/*        nativeControlId='my-checkbox'*/}
                                {/*        checked={this.state.checked}*/}
                                {/*        indeterminate={this.state.indeterminate}*/}
                                {/*        onChange={(e) => this.setState({*/}
                                {/*            checked: e.target.checked,*/}
                                {/*            indeterminate: e.target.indeterminate})*/}
                                {/*        }*/}
                                {/*    />*/}
                                {/*    <label htmlFor='my-checkbox'>Tenis</label>*/}
                                {/*</div>*/}
                                {/*<div className="mdc-form-field">*/}
                                {/*    <Checkbox*/}
                                {/*        nativeControlId='my-checkbox'*/}
                                {/*        checked={this.state.checked}*/}
                                {/*        indeterminate={this.state.indeterminate}*/}
                                {/*        onChange={(e) => this.setState({*/}
                                {/*            checked: e.target.checked,*/}
                                {/*            indeterminate: e.target.indeterminate})*/}
                                {/*        }*/}
                                {/*    />*/}
                                {/*    <label htmlFor='my-checkbox'>Triathlon</label>*/}
                                {/*</div>*/}
                                {/*<div className="mdc-form-field">*/}
                                {/*    <Checkbox*/}
                                {/*        nativeControlId='my-checkbox'*/}
                                {/*        checked={this.state.checked}*/}
                                {/*        indeterminate={this.state.indeterminate}*/}
                                {/*        onChange={(e) => this.setState({*/}
                                {/*            checked: e.target.checked,*/}
                                {/*            indeterminate: e.target.indeterminate})*/}
                                {/*        }*/}
                                {/*    />*/}
                                {/*    <label htmlFor='my-checkbox'>CrossFit</label>*/}
                                {/*</div>*/}
                            </div>
                            <div className='slide mainContainer'>
                                <TextField
                                    className='fullWidth'
                                    label='Contraseña Actual'
                                    helperText={<HelperText>{this.state.passerrors.pass0 ? this.state.passerrors.pass0 : ''}</HelperText>}
                                ><Input
                                    required
                                    type="password"
                                    maxLength="100"
                                    id="pass0"
                                    name="pass0"
                                    value={this.state.pass0}
                                    onChange={this.handlePassInput} />
                                </TextField>
                                <TextField
                                    className='fullWidth'
                                    label='Nueva Contraseña'
                                    helperText={<HelperText>{this.state.passerrors.pass1 ? this.state.passerrors.pass1 : ''}</HelperText>}
                                ><Input
                                    required
                                    type="password"
                                    maxLength="100"
                                    id="pass1"
                                    name="pass1"
                                    value={this.state.pass1}
                                    onChange={this.handlePassInput} />
                                </TextField>
                                <TextField
                                    className='fullWidth'
                                    label='Repetir Contraseña'
                                    helperText={<HelperText>{this.state.passerrors.pass2 ? this.state.passerrors.pass2 : ''}</HelperText>}
                                ><Input
                                    required
                                    type="password"
                                    maxLength="100"
                                    id="pass2"
                                    name="pass2"
                                    value={this.state.pass2}
                                    onChange={this.handlePassInput} />
                                </TextField>
                                <Button raised
                                        className="fullWidth"
                                        onClick={this.handlePassSubmit}
                                >Cambiar Contraseña</Button>

                            </div>
                        </SwipeableViews>
                    </form>
                    <div className="saveButtonContainer">
                        <Button raised
                                onClick={Object.keys(this.state.errors).length ? this.showErrors : this.handleSubmit}
                                className={this.state.slideIndex === 3 ? 'hidden ': ''}
                        >Guardar Cambios</Button>
                    </div>
                </div>
            </MuiPickersUtilsProvider>
        );
    }
}
function mapStateToProps(state) {
    return state.userReducer;
}
function mapDispatchToProps(dispatch) {
    return {
        navigation: bindActionCreators(navigationActions, dispatch),
        user: bindActionCreators(userActions, dispatch),
        alertActions: bindActionCreators(alertActions, dispatch)
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(Account);