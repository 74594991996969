import { alertActions }     from './alertActions';
import { authActions }     from './authActions';
import { userActionTypes }    from '../constants/userActionTypes';
import { userService }      from '../services/userService';
import { navigationActions } from "./navigationActions";
import {navigationActionTypes} from "../constants/navigationActionTypes";

export const userActions = {
    getData,
    getDataHome,
    getCashback,
    setLoggedUser,
    handleInput,
    edit,
    changePass,
    register,
};

function getErrorMessage(error) {
    let message = "";
    switch (error.status) {
        case 400:
        case 401:
        case 404:
            message = error.data;
            break;
        case 500:
        case 502:
            message = error.statusText;
            break;
        default:
            message = 'Ha habido un problema con la conexion, compruebe estar conectado.';
            break;
    }
    return message;
}

function getDataHome() {
    return dispatch => {
        dispatch(request());
        userService.info()
            .then(
                user => {
                    dispatch(success(user));
                    dispatch(navigationActions.setAppTitle(getUserAppTitle(user)));
                    dispatch(userActions.getCashback());
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(getErrorMessage(error)))
                }
            );
    };

    function request()      { return { type: userActionTypes.GET_DATA_REQUEST } }
    function success(user)  { return { type: userActionTypes.GET_DATA_SUCCESS, user } }
    function failure(error) { return { type: userActionTypes.GET_DATA_FAILURE, error } }

    function getUserAppTitle(user){
        return 'GNC BENEfit ' + (user.TIER === 'PRO' ? '<strong>PRO</strong>':'');
    }

}

function getData() {
    return dispatch => {
        dispatch(request());
        userService.info()
            .then(
                user => {
                    dispatch(success(user));
                    dispatch(userActions.getCashback());
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(getErrorMessage(error)))
                }
            );
    };

    function request()      { return { type: userActionTypes.GET_DATA_REQUEST } }
    function success(user)  { return { type: userActionTypes.GET_DATA_SUCCESS, user } }
    function failure(error) { return { type: userActionTypes.GET_DATA_FAILURE, error } }
}

function getCashback() {
    return dispatch => {
        dispatch(request());
        userService.cashback()
            .then(
                cashback => {
                    if(cashback.status) {
                         dispatch(failure(cashback.statusText));
                    } else {
                         dispatch(success(format(cashback)));
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(getErrorMessage(error)));
                }
            );
    };

    function request() { return { type: userActionTypes.GET_CASHBACK_REQUEST } }
    function success(cashback) { return { type: userActionTypes.GET_CASHBACK_SUCCESS, cashback } }
    function failure(error) { return { type: userActionTypes.GET_CASHBACK_FAILURE, error } }

    function format(cashback){
        if(cashback.length>0){
            let cashbackAmount = 0;
            let lastPurchase = undefined;
            const today = new Date();
            cashback.forEach(function(val,index){
                if(!cashback[index + 1]) lastPurchase = new Date(val.FECHA);
                if(val.TIPO_MOVIMIENTO === 'C'){
                    cashbackAmount += val.PUNTOS;
                }else{
                    cashbackAmount -= val.PUNTOS;
                }
                val.CB_ACUM = parseFloat(cashbackAmount).toFixed(2);
                val.PUNTOS = parseFloat(val.PUNTOS).toFixed(2);
                let date = new Date(val.FECHA);
                let diff = parseInt((today-date)/(24*3600*1000));
                val.HIST_FILTRO = (diff<=30)?1:((diff<=365)?2:3);
                val.FECHA = date.getDate()+'/'+(date.getMonth()+1)+'/'+date.getFullYear();
            });
            cashback.reverse();
            console.log({lastPurchase: lastPurchase, cashbackAmount: cashbackAmount, cashback: cashback});
            return {lastPurchase: lastPurchase, cashbackAmount: cashbackAmount, cashback: cashback}
        }else{
            return undefined;
        }
    }

}

function setLoggedUser(user) {
    return dispatch => {
        dispatch(request(user));
    };
    function request(user)  { return { type: userActionTypes.SET_LOGGED_USER_REQUEST, user }}
}

function edit(new_data) {
    return dispatch => {
        dispatch(request());

        userService.edit(new_data)
            .then(
                user => {
                    if(!user.status){
                        this.getData();
                        dispatch(success(user));
                        dispatch(alertActions.success('¡Perfil Actualizado!'));
                    }else{
                        throw(user);
                    }
                })
            .catch(
                error => {
                    this.getData();
                    dispatch(failure(error));
                    dispatch(alertActions.error(getErrorMessage(error)));
                });

    };

    function request()          { return { type: userActionTypes.EDIT_REQUEST } }
    function success(user)   { return { type: userActionTypes.EDIT_SUCCESS, user } }
    function failure(error)     { return { type: userActionTypes.EDIT_FAILURE, error } }
}

function handleInput(new_value) {
    return dispatch => {
        dispatch(request(new_value));
    };
    function request(value) { return { type: userActionTypes.CHANGE_VALUE, value } }
}

function register(new_data) {
    return dispatch => {
        dispatch(request());

        userService.register(new_data)
            .then(
                user => {
                    if(!user.status){
                        dispatch(success(user));
                        dispatch(alertActions.success('¡Perfil Creado!'));
                        dispatch(redirect());
                    }else{
                        throw(user);
                    }
                })
            .catch(
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(getErrorMessage(error)));
                });
    };

    function request()       { return { type: userActionTypes.REGISTER_REQUEST } }
    function success(user)   { return { type: userActionTypes.REGISTER_SUCCESS, user } }
    function redirect()   { return { type: navigationActionTypes.REDIRECT_LOGIN_TRUE } }
    function failure(error)  { return { type: userActionTypes.REGISTER_FAILURE, error } }
}

function changePass(pass) {
    return dispatch => {
        dispatch(request());

        userService.changePass(pass)
            .then(
                user => {
                    if(!user.status){
                        dispatch(success(user));
                        dispatch(alertActions.success('¡Contraseña Modificada!'));
                        dispatch(authActions.login(user.email,pass));
                        //dispatch(userActions.getData());
                    }else{
                        throw(user);
                    }
                })
            .catch(
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(getErrorMessage(error)));
                });
    };

    function request()       { return { type: userActionTypes.CHANGEPASS_REQUEST } }
    function success(user)   { return { type: userActionTypes.CHANGEPASS_SUCCESS, user } }
    function failure(error)  { return { type: userActionTypes.CHANGEPASS_FAILURE, error } }
}