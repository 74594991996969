import {combineReducers} from 'redux';

import authReducer  from './authReducer';
import alertReducer from './alertReducer';
import navigationReducer from './navigationReducer';

import dealsReducer from './dealsReducer';

import userReducer from './userReducer';
import {authActionTypes} from "../constants/authActionTypes";
// import athleteReducer                   from './athleteReducer';
// import trainerReducer                   from './trainerReducer';
//
// import routineReducer                   from './routineReducer';
// import routinePlannerReducer            from './routinePlannerReducer';
// import exerciseReducer                  from './exerciseReducer';


const appReducer = combineReducers({
    authReducer,
    userReducer,
    alertReducer,
    navigationReducer,
    dealsReducer,
});

const rootReducer = (state, action) => {
    if (action.type === authActionTypes.LOGOUT) {
        state = undefined
    }
  return appReducer(state, action)
}

export default rootReducer;