import { dealsActionTypes } from '../constants/dealsActionTypes';

const initialState = {
    deals: {},
};

export default function dealsReducer(state=initialState, action) {
    switch (action.type) {
        case dealsActionTypes.ALL_DEALS:
            return Object.assign({}, state, {
                deals: action.getDealss
            });
        default:
            return state;
    }
}