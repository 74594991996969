import { alertActionTypes } from '../constants/alertActionTypes';

export const alertActions = {
    success,
    error,
    warning,
    info,
    clear
};

function success(message) {
    return { type: alertActionTypes.ALERT_SUCCESS, message };
}

function error(message) {
    return { type: alertActionTypes.ALERT_ERROR, message };
}

function warning(message) {
    return { type: alertActionTypes.ALERT_WARNING, message };
}

function info(message) {
    return { type: alertActionTypes.ALERT_INFO, message };
}

function clear() {
    return { type: alertActionTypes.ALERT_CLEAR };
}