import React, { Component } from 'react';
import {bindActionCreators} from "redux";
import { connect } from "react-redux";
import {navigationActions} from "../actions/navigationActions";
import { usePapaParse } from 'react-papaparse';

import TitleCard from '../components/TitleCard';
import MaterialIcon from '@material/react-material-icon';
import List, {ListItem, ListItemMeta} from '@material/react-list';
import {Headline6, Body2, Subtitle2} from '@material/react-typography';
import IconButton from "@material/react-icon-button";

function mapDispatchToProps(dispatch) {
    return {
        navigation: bindActionCreators(navigationActions, dispatch),
    };
}

class Faqs extends Component{
    state = {
        menuIndex: 6,
        appTitle: 'Preguntas Frecuentes',
        faqs: [],
        current: 0
    };
    constructor(props) {
        super(props);
        this.props.navigation.setAppTitle(this.state.appTitle);
        this.props.navigation.setMenuIndex(this.state.menuIndex);
    }

    toggle(index) {
        this.setState({
            current: index
        })
    }
    componentDidMount() {
        const { readRemoteFile } = usePapaParse();

        readRemoteFile('https://docs.google.com/spreadsheets/d/e/2PACX-1vSCTIIC7n4czpKHBW1xySu-Bs9L20jlkjQQLgiUUsOIl04DNiOCpm7M6SovGWLJZYUFkcrJIi_ihr-e/pub?output=csv', {
            complete: (results) => {
                // console.log('---------------------------');
                // console.log('Results:', results.data);
                // console.log('---------------------------');
                this.setState({
                    faqs: results.data
                })
            },
        });
    }
    render() {
        const { faqs } = this.state;
        const { userData } = this.props;
        let filteredFaqs = faqs.filter(function (el) {
                    return el[0] === userData.TIER ||
                        el[0] === 'ALL';
                });
        return (
            <div>
                <div className='faqFabContainer'>
                    <IconButton className='secondary-filled-button mdc-button--raised' isLink={true} href='tel:+50224221338'>
                        <i className="fa fa-phone-alt"></i>
                    </IconButton>
                </div>
                <div className='mainContainer'>
                    <TitleCard
                        icon='help'
                        bigText='FAQs'
                    />
                    <List>
                        {
                            filteredFaqs.map((obj,i) => {
                                return (
                                    <div key={i}>
                                        <ListItem onClick={this.toggle.bind(this, i)}>
                                            <Subtitle2 tag='span'>{obj[1]}</Subtitle2>
                                            <ListItemMeta meta={<MaterialIcon icon={this.state.current === i ? 'expand_less': 'expand_more'}/>} />
                                        </ListItem>
                                        <div className={this.state.current === i ? 'padding16 collapsible open ': 'padding16 collapsible'}>
                                            <Headline6 tag='span'>{obj[2]}</Headline6>
                                            <Body2 tag='h3' className="greyed">{obj[3]}</Body2>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </List>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return state.userReducer;
}
export default connect(mapStateToProps, mapDispatchToProps)(Faqs);