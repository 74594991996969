import React, { Component } from 'react';
import Card, {CardActions, CardActionButtons,} from '@material/react-card';
import Button from '@material/react-button';
import {Subtitle2} from '@material/react-typography';

class HomeCashbackCard extends Component{
    render() {
        return (
            <Card className='margTop'>
                <div className="padding16">
                    <Subtitle2 className='greyed noMargTop' tag='h3'>Tu Cash Disponible</Subtitle2>
                    <span className="homeCashbackAmount">Q{this.props.cashbackAmount}</span>
                </div>
                <CardActions>
                    <CardActionButtons>
                        <Button onClick={this.props.gotoHistory}>Ver Historial</Button>
                    </CardActionButtons>
                </CardActions>
            </Card>
        );
    }
}

export default HomeCashbackCard;