import * as JWT from 'jwt-decode'

export function LoggedUser() {
    // return authorization header with jwt token
    let user = JSON.parse(localStorage.getItem('user'));
    if (user && user.token) {
        let user_data = JWT(user.token);
        return user_data;
    } else {
        return {};
    }

}