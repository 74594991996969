import React, { Component } from 'react';
import Card, {
    CardPrimaryContent,
    CardMedia,
    CardActions,
    CardActionButtons,
    CardActionIcons,
} from "@material/react-card";
import Button from '@material/react-button';
import IconButton from "@material/react-icon-button";
import MaterialIcon from "@material/react-material-icon";
import {Subtitle2, Headline6} from '@material/react-typography';

class DealsCard extends Component{
    render() {
        let double = '';
        if (this.props.doubleCash==='1') {
            double = <CardActionIcons><IconButton className='secondary-filled-button'><MaterialIcon icon='monetization_on' className='smallFont' /><MaterialIcon icon='monetization_on' className='smallFont' /></IconButton>{/*<span className=\"smallFont strong\">x2</span>*/}</CardActionIcons>
        }
        return (
            <Card className='margTop'>
                <CardPrimaryContent>
                    <CardMedia wide imageUrl={this.props.imgURL} />
                </CardPrimaryContent>
                <div className="dealContent">
                    <Subtitle2 tag='h3' className="greyed noMargTop">Promoción válida del {this.props.fechaDesde} al {this.props.fechaHasta}</Subtitle2>
                    <Headline6 tag='span'>{this.props.titulo}</Headline6>
                </div>

                <CardActions>
                    <CardActionButtons>
                        <Button href={this.props.URL} target='_blank'>Ir al sitio</Button>
                    </CardActionButtons>
                    {double}
                </CardActions>
            </Card>
        );
    }
}

export default DealsCard;