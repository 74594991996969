import requestHandler  from "./requestHandler";
import requestHandler2  from "./requestHandler2";

export const userService = {
    login,
    logout,
    info,
    cashback,
    edit,
    restorePass,
    changePass,
    register
};

function info() {
    let user = JSON.parse(localStorage.getItem('user'));
    if(user) {
        let requestOptions = {
            url: '/socios/'+user.user.IDENTIFICACION,
            method: 'GET'
        };
        return requestHandler(requestOptions)
            .then(user => {
                return user;
            }).catch(error => {
                return error;
            });
    }
    return logout();
}

function cashback() {
    let user = JSON.parse(localStorage.getItem('user'));
    if(user) {
        let requestOptions = {
            url: '/cashback/'+user.user.IDENTIFICACION,
            method: 'GET'
        };
        return requestHandler(requestOptions)
            .then(cashback => {
                return cashback;
            }).catch(error => {
                return error;
            });
    }
    return logout();
}

function login(email, password) {
    let data = {
        email: email,
        password: password
    };
    let requestOptions = {
        url:    '/login',
        method: 'POST',
        data: data
    };
    return requestHandler(requestOptions)
        .then(user => {
            // login successful if there's a jwt token in the response
            if (user.token) {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('user', JSON.stringify(user));
                return user;
            }
            throw new Error(user.message);
        })
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
}

function edit(new_data) {
    let fechanacimiento = "";
    if(new_data.FECHA_NACIMIENTO!=="" && new_data.FECHA_NACIMIENTO!=="null" && new_data.FECHA_NACIMIENTO!==null){
        let fechanac = new Date(new_data.FECHA_NACIMIENTO);
        fechanacimiento = fechanac.getDate() +"/"+ (fechanac.getMonth()+1) + "/" + fechanac.getFullYear();
    }
    let estadocivil = new_data.ESTADO_CIVIL;
    if(estadocivil==="Soltero")estadocivil=0;
    if(estadocivil==="Casado")estadocivil=1;

    let data = {
        "nombre": ""+new_data.NOMBRE_COMPLETO,
        "email": ""+new_data.EMAIL,
        "identificacion": ""+new_data.DPI,
        "fechanacimiento": fechanacimiento,
        "telefono": ""+new_data.TELEFONO,
        "sexo": ""+new_data.GENERO,
        "region1": (new_data.REGION1!==""?""+new_data.REGION1:null),
        "direccion1": ""+new_data.DIRECCION,
        "nit": ""+new_data.NIT,
        "deportes": "TENIS",
        "estadocivil": (estadocivil!==""?""+estadocivil:null),
        "canthijos": (new_data.HIJOS!==""?""+new_data.HIJOS:null),
        "tipoidentificacion": ""+new_data.TIPOIDENTIFICACION,
    };
    console.log(data);
    let requestOptions = {
        url: "/socios",
        method: 'PUT',
        data: data
    };

    return requestHandler(requestOptions)
        .then(user => {
            return user;
        }).catch(error => {
            return error;
        });
}

function register(new_data) {
    let fechanacimiento = "";
    if(new_data.fechanacimiento!=="" && new_data.fechanacimiento!=="null" && new_data.fechanacimiento!==null){
        let fechanac = new Date(new_data.fechanacimiento);
        fechanacimiento = fechanac.getDate() +"/"+ (fechanac.getMonth()+1) + "/" + fechanac.getFullYear();
    }
    let estadocivil = new_data.estadocivil;
    if(estadocivil==="Soltero")estadocivil=0;
    if(estadocivil==="Casado")estadocivil=1;
    let data = {
        "nombre": ""+new_data.nombre,
        "email": ""+new_data.email,
        "identificacion": ""+new_data.identificacion,
        "fechanacimiento": fechanacimiento,
        "telefono": ""+new_data.telefono,
        "sexo": ""+new_data.sexo,
        "region1": (new_data.region1!==""?""+new_data.region1:null),
        "direccion1": ""+new_data.direccion1,
        "tipomembresia": "1",
        "nit": ""+new_data.nit,
        "deportes": 'TENIS',
        "estadocivil": (estadocivil!==""?""+estadocivil:null),
        "canthijos": (new_data.canthijos!==""?""+new_data.canthijos:null),
        "password": ""+new_data.password,
        "tipoidentificacion": ""+(new_data.selectedChipIds[0]==="D"?"D":"P"),
    };
    console.log(data);

    let requestOptions = {
        url:    '/socios',
        method: 'POST',
        data: data
    };

    return requestHandler(requestOptions)
        .then(user => {
            return user;
        }).catch(error => {
            return error;
        });
}

function restorePass(email){

    let data = {
        "email": ""+email,
    };
    console.log(data);

    let requestOptions = {
        url:    '/updpassword',
        method: 'POST',
        data: data
    };

    return requestHandler2(requestOptions)
        .then(auth => {
            return auth;
        }).catch(error => {
            return error;
        });
}

function changePass(pass) {
    let user = JSON.parse(localStorage.getItem('user'));
    let data = {
        "email": ""+user.user.EMAIL,
        "password": ""+pass
    };
    let requestOptions = {
        url: "/updpassword",
        method: 'PUT',
        data: data
    };

    return requestHandler2(requestOptions)
        .then(user => {
            return user;
        }).catch(error => {
            return error;
        });
}