import { navigationActionTypes } from '../constants/navigationActionTypes';

const initialState = {
    appTitle: '',
    menuSelectedIndex: 0,
    redirectToLogin: false
};

export default function navigationReducer(state=initialState, action) {
    switch (action.type) {
        case navigationActionTypes.APP_TITLE:
            return Object.assign({}, state, {
                appTitle: action.newAppTitle
            });
        case navigationActionTypes.MENU_INDEX:
            return Object.assign({}, state, {
                menuSelectedIndex: action.newMenuSelectedIndex
            });
        case navigationActionTypes.REDIRECT_LOGIN_TRUE:
            return Object.assign({}, state, {
                redirectToLogin: true
            });
        case navigationActionTypes.REDIRECT_LOGIN_FALSE:
            return Object.assign({}, state, {
                redirectToLogin: false
            });
        default:
            return state;
    }
}