import React, { Component } from 'react';
import {bindActionCreators} from "redux";
import { connect } from "react-redux";
import {navigationActions} from "../actions/navigationActions";
import { usePapaParse } from 'react-papaparse';
import Button from "@material/react-button";

function mapDispatchToProps(dispatch) {
    return {
        navigation: bindActionCreators(navigationActions, dispatch),
    };
}

class Plan extends Component{
    state = {
        menuIndex: 7,
        appTitle: '¿Cómo Redimir?',
        imgUrls: {
            0: {TIER:'BENEfit', ImgURL:''},
            1: {TIER:'BENEfit PRO', ImgURL:''}
        }
    };
    constructor(props) {
        super(props);
        this.props.navigation.setAppTitle(this.state.appTitle);
        this.props.navigation.setMenuIndex(this.state.menuIndex);
    }

    componentDidMount() {
        const { readRemoteFile } = usePapaParse();

        readRemoteFile('https://docs.google.com/spreadsheets/d/e/2PACX-1vTEqbx5rxywtjUE3LiMW-ZvShSY8jibJjP7209wx6onxjDEMgAVF1Po4w9RHazy106Nmktqq26IEU0i/pub?output=csv', {
            complete: (results) => {
                // console.log('---------------------------');
                // console.log('Results:', results.data);
                // console.log('---------------------------');
                this.setState({
                    imgUrls: results.data
                })
            },
        });
    }

    render() {
        const { imgUrls } = this.state;
        const { userData } = this.props;
        let divImage = {
                backgroundImage : "url('')"
            };
        if(imgUrls.length > 0){
            let filteredImgUrls = imgUrls.filter(function (el) {
                    return el[0] === userData.TIER });
            console.log(filteredImgUrls);
            divImage = {
                backgroundImage : "url('"+ filteredImgUrls[0][1] +"')"
            };
        }

        return (
                <div className='slide heightWithTopbar planbgpro' style={divImage}>
                </div>
        );
    }
}
function mapStateToProps(state) {
    return state.userReducer;
}
export default connect(mapStateToProps, mapDispatchToProps)(Plan);