import { userActionTypes } from '../constants/userActionTypes';

const initialState = {
    loading: true,
    userData:'',
    userLogged:'',
    cashbackData: undefined,
    items: []
};

export default function userReducer (state = initialState, action) {
    switch (action.type) {
        case userActionTypes.GET_DATA_SUCCESS:
            return Object.assign({}, state, {
                loading: false,
                userData: action.user
            });
        case userActionTypes.GET_CASHBACK_SUCCESS:
            return Object.assign({}, state, {
                loading: false,
                cashbackData: action.cashback
            });
        case userActionTypes.SET_LOGGED_USER_REQUEST:
            return Object.assign({}, state, {
                loading: false,
                userLogged: action.user
            });
        case userActionTypes.EDIT_REQUEST:
            return Object.assign({}, state, {
                loading: true,
            });
        case userActionTypes.EDIT_SUCCESS:
            return Object.assign({}, state, {
                loading: false,
                userData: action.user
            });
        case userActionTypes.EDIT_FAILURE:
            return Object.assign({}, state, {
                error: action.error,
                loading: false
            });
        case userActionTypes.CHANGE_VALUE:
            const new_user = Object.assign({}, state.userData, action.value);
            return Object.assign({}, state, {
                loading: false,
                userData: new_user
            });
        default:
            return state
    }
}