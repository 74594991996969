import React, { Component } from 'react';
import {bindActionCreators} from "redux";
import { connect } from "react-redux";
import {navigationActions} from "../actions/navigationActions";
import {userActions} from "../actions/userActions";
import {alertActions} from "../actions/alertActions";
import {Redirect} from "react-router-dom";

import TopAppBar, {TopAppBarFixedAdjust, TopAppBarIcon, TopAppBarRow, TopAppBarSection, TopAppBarTitle} from '@material/react-top-app-bar';
import Tab from '@material/react-tab';
import TabBar from '@material/react-tab-bar';
import MaterialIcon from "@material/react-material-icon";
import Button from '@material/react-button';
import TextField, {HelperText, Input} from '@material/react-text-field';
import {ChipSet, Chip} from '@material/react-chips';
import Select, {Option} from '@material/react-select';
import Checkbox from '@material/react-checkbox';
import SwipeableViews from 'react-swipeable-views';
import MaskedInput from 'react-text-mask';
import MomentUtils from '@date-io/moment';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import Dialog, {
  DialogTitle,
  DialogContent,
  DialogFooter,
  DialogButton,
} from '@material/react-dialog';

class Register extends Component{
    state = {
        appTitle: 'Crear Cuenta',
        slideIndex: 0,
        errors: {
            nombre: 'Debe ingresar un Nombre válido.',
            email: 'Debe ingresar un mail válido.',
            identificacion: 'Debe ingresar un DPI/Pasaporte válido.',
            telefono: 'Debe ingresar un Celular válido.',
            password: 'Debe ingresar una Contraseña válida.',
            //fechanacimiento: 'Debe ingresar una Fecha de Nacimiento válida.',
            //region1: 'Debe ingresar un Departamento válido',
        },
        openModal: false,
        telFocused: false,
        nombre: '',
        email: '',
        identificacion: '',
        fechanacimiento: null,
        telefono: '',
        sexo: '',
        region1: '',
        direccion1: '',
        tipomembresia: '1',
        nit: '',
        deportes: '',
        estadocivil: '',
        canthijos: '',
        password: '',
        selectedChipIds: ['D'],
    };

    constructor(props) {
        super(props);
        this.props.navigation.setAppTitle(this.state.appTitle);
        this.props.navigation.setMenuIndex(this.state.menuIndex);
        this.goBack = this.goBack.bind(this);
    }

    goBack(){
        this.props.history.goBack();
    }

    handleSlideIndexUpdate = (slideIndex) => this.setState({slideIndex});

    handleDateChange = date => {
        this.setState({ fechanacimiento: date });
        //console.log(this.state);
        console.log(date);
        let new_state;
        let new_date = new Date(date);
        const today = new Date();
        //console.log(this.isValidDate(new_date));
        if(!this.isValidDate(new_date) || new_date>today){
            let errors = Object.assign({},this.state.errors, {fechanacimiento:'Debe ingresar una Fecha de nacimiento válida.'});
            new_state = Object.assign({}, this.state, {errors: errors} );
        } else {
            delete this.state.errors.fechanacimiento;
            new_state = Object.assign({}, this.state);
        }
        this.setState(new_state);
        this.setState({ fechanacimiento: date });
    };

    isValidDate(d) {
        return d instanceof Date && !isNaN(d);
    }

    handleInput = event =>  {
        const { name, value, required } = event.target;
        let new_state;
        let msg = event.target.getAttribute('errormsg')?event.target.getAttribute('errormsg'):'Debes completar este campo.';
        if(!value && required) {
            let errors = Object.assign({},this.state.errors, {[name]:msg});
            new_state = Object.assign({}, this.state, {errors: errors} );
        } else {
            delete this.state.errors[name];
            new_state = Object.assign({}, this.state);
        }

        this.setState(new_state);
        this.setState({[name]:value});
    };

    handleEmailInput = event => {
        const { value } = event.target;
        let new_state;
        if(!value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
            let errors = Object.assign({},this.state.errors, {email:'Debes colocar un mail válido.'});
            new_state = Object.assign({}, this.state, {errors: errors} );
        } else {
            delete this.state.errors.email;
            new_state = Object.assign({}, this.state);
        }
        this.setState(new_state);
        this.setState({email:value});
        console.log(this.state);
    };

    handleDirInput = event => {
        const { value } = event.target;
        this.setState({ region1: value });
    };

    handleSubmit = () => {
        console.log(this.state.checked);
        if(this.state.checked){
            this.props.user.register(this.state);
        }else{
            this.props.alertActions.error("Debes aceptar los términos y condiciones.")
        }
    };

    showErrors = () => {
        let msg = "";
        Object.keys(this.state.errors).map(key =>
            msg += this.state.errors[key]+' <br> '
        );
        this.props.alertActions.error(msg);
    };

    handleTelFocus = () => {
        this.setState({ telFocused: true });
    };

    handleTelBlur = () => {
        if(!this.state.telefono){
            this.setState({ telFocused: false });
        }
    };

    handleOpenModal = () => {
        this.setState({ openModal: true });
    };

    handleFilter(selectedChipIds){
        if(selectedChipIds.length === 0){
            selectedChipIds = ["D"];
        }
        this.setState({
            selectedChipIds: selectedChipIds
        });
    }

    render() {
        if(this.props.redirectToLogin){
            return (
                <Redirect
                    to={{
                        pathname: "/login",
                    }}
                />
          );
        }
        return (
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <div>
                    <TopAppBar dense={true}>
                        <TopAppBarRow>
                            <TopAppBarSection align='start'>
                                <TopAppBarIcon navIcon tabIndex={0}>
                                    <MaterialIcon hasRipple icon='arrow_back' onClick={this.goBack}/>
                                </TopAppBarIcon>
                                <TopAppBarTitle><div dangerouslySetInnerHTML={{__html: this.state.appTitle}}/></TopAppBarTitle>
                            </TopAppBarSection>
                        </TopAppBarRow>
                    </TopAppBar>
                    <TopAppBarFixedAdjust>
                        <div className='tabContainer'>
                            <TabBar
                                activeIndex={this.state.slideIndex}
                                handleActiveIndexUpdate={this.handleSlideIndexUpdate}
                            >
                                <Tab>
                                    <span className="mdc-tab__content">
                                        <MaterialIcon className='mdc-tab__icon' icon='fingerprint'/>
                                        <span className="mdc-tab__text-label"> 1</span>
                                    </span>
                                </Tab>
                                <Tab>
                                    <span className="mdc-tab__content">
                                        <MaterialIcon className='mdc-tab__icon' icon='person'/>
                                        <span className="mdc-tab__text-label"> 2</span>
                                    </span>
                                </Tab>
                            </TabBar>
                        </div>
                        <SwipeableViews
                            index={this.state.slideIndex}
                            onChangeIndex={this.handleSlideIndexUpdate}
                            className='margTop'
                        >
                            <div className='slide mainContainer'>
                                <TextField
                                    className='fullWidth'
                                    label='Nombre Completo'
                                    helperText={<HelperText>{this.state.errors.nombre ? this.state.errors.nombre : ''}</HelperText>}
                                ><Input
                                    required
                                    errormsg="Debe ingresar un Nombre válido."
                                    maxLength="100"
                                    id="nombre"
                                    name="nombre"
                                    value={this.state.nombre}
                                    onChange={this.handleInput} />
                                </TextField>
                                <TextField
                                    className='fullWidth'
                                    label='Email'
                                    helperText={<HelperText>{this.state.errors.email ? this.state.errors.email : ''}</HelperText>}
                                ><Input
                                    required
                                    maxLength="55"
                                    id="email"
                                    name="email"
                                    value={this.state.email}
                                    onChange={this.handleEmailInput} />
                                </TextField>
                                <ChipSet
                                    choice
                                    selectedChipIds={this.state.selectedChipIds}
                                    handleSelect={(selectedChipIds) => this.handleFilter(selectedChipIds)}
                                >
                                    <Chip id={'D'} label='DPI' />/
                                    <Chip id={'P'} label='Pasaporte' />
                                </ChipSet>
                                <TextField
                                    className='fullWidth'
                                    label={this.state.selectedChipIds[0]==='D' ? 'DPI' : 'Pasaporte'}
                                    helperText={<HelperText>{this.state.errors.identificacion ? this.state.errors.identificacion : ''}</HelperText>}
                                ><Input
                                    required
                                    errormsg="Debe ingresar un DPI/Pasaporte válido."
                                    maxLength="20"
                                    id="identificacion"
                                    name="identificacion"
                                    value={this.state.identificacion}
                                    onChange={this.handleInput} />
                                </TextField>
                                <div className="mdc-text-field fullWidth">
                                    <MaskedInput
                                        className="mdc-text-field__input"
                                        mask={[/\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                                        required
                                        errormsg="Debe ingresar un Celular válido."
                                        maxLength="100"
                                        id="telefono"
                                        name="telefono"
                                        value={this.state.telefono}
                                        onChange={this.handleInput}
                                        onFocus={this.handleTelFocus}
                                        onBlur={this.handleTelBlur}
                                    />
                                    <label className={'mdc-floating-label'+ (this.state.telFocused ? ' mdc-floating-label--float-above':'')} htmlFor="TELEFONO">Celular</label>
                                    <div className="mdc-line-ripple"></div>
                                </div>
                                <div className="mdc-text-field-helper-line">
                                    <p className="mdc-text-field-helper-text" aria-hidden="false">{this.state.errors.telefono ? this.state.errors.telefono : ''}</p>
                                </div>
                                {/*<TextField*/}
                                {/*    className='fullWidth'*/}
                                {/*    label='Celular'*/}
                                {/*    helperText={<HelperText>{this.state.errors.telefono ? this.state.errors.telefono : ''}</HelperText>}*/}
                                {/*><Input*/}
                                {/*    required*/}
                                {/*    maxLength="100"*/}
                                {/*    id="telefono"*/}
                                {/*    name="telefono"*/}
                                {/*    value={this.state.telefono}*/}
                                {/*    onChange={this.handleInput} />*/}
                                {/*</TextField>*/}
                                <TextField
                                    className='fullWidth'
                                    label='NIT'
                                    helperText={<HelperText>{this.state.errors.nit ? this.state.errors.nit : ''}</HelperText>}
                                ><Input
                                    maxLength="30"
                                    id="nit"
                                    name="nit"
                                    // className={classes.textField}
                                    onChange={this.handleInput}
                                    value={ this.state.nit != null ? this.state.nit : ''}
                                    //error={!!this.state.errors.NIT}
                                />
                                </TextField>
                                <TextField
                                    className='fullWidth'
                                    label='Contraseña'
                                    helperText={<HelperText>{this.state.errors.password ? this.state.errors.password : ''}</HelperText>}
                                ><Input
                                    required
                                    errormsg="Debe ingresar una Contraseña válida."
                                    type="password"
                                    maxLength="100"
                                    id="password"
                                    name="password"
                                    value={this.state.password}
                                    onChange={this.handleInput} />
                                </TextField>
                                <div className='textRight'>
                                    <Button raised trailingIcon={<MaterialIcon icon='navigate_next'/>} onClick={() => this.setState({slideIndex: this.state.slideIndex+1})}>
                                        Siguiente
                                    </Button>
                                </div>
                            </div>
                            <div className='slide mainContainer'>
                                <KeyboardDatePicker
                                        disableFuture
                                        allowKeyboardControl={false}
                                        errormsg="Debe ingresar una Fecha de Nacimiento válida."
                                        name="fechanacimiento"
                                        openTo="year"
                                        format="DD/MM/YYYY"
                                        label="Fecha de Nacimiento"
                                        views={["year", "month", "date"]}
                                        className='fullWidth'
                                        //className={classes.textField}
                                        inputVariant="filled"
                                        value={this.state.fechanacimiento}
                                        onChange={this.handleDateChange}
                                        //error={!!this.state.errors.fechanacimiento}
                                        helperText={this.state.errors.fechanacimiento ? this.state.errors.fechanacimiento : ''}
                                    />
                                    <HelperText>{this.state.errors.fechanacimiento ? this.state.errors.fechanacimiento : ''}</HelperText>
                                <Select
                                    errormsg="Debe ingresar un Género válido."
                                    className='fullWidth'
                                    label='Género'
                                    id="sexo"
                                    name="sexo"
                                    value={this.state.sexo != null ? ""+this.state.sexo : ''}
                                    onChange={this.handleInput}
                                    helperText={<HelperText>{this.state.errors.sexo ? this.state.errors.sexo : ''}</HelperText>}
                                >
                                    <Option value=''></Option>
                                    <Option value='F'>Femenino</Option>
                                    <Option value='M'>Masculino</Option>
                                </Select>
                                <Select
                                    label='Estado Civil'
                                    id="estadocivil"
                                    name="estadocivil"
                                    className='fullWidth'
                                    // className={classes.textField}
                                    onChange={this.handleInput}
                                    value={ this.state.estadocivil != null ? ""+this.state.estadocivil : ''}
                                    //error={!!this.state.errors.estadocivil}
                                    helperText={<HelperText>{this.state.errors.estadocivil ? this.state.errors.estadocivil : ''}</HelperText>}
                                >
                                    <Option value=''></Option>
                                    <Option value='Casado'>Casado</Option>
                                    <Option value='Soltero'>Soltero</Option>
                                </Select>
                                <Select
                                    label='Hijos'
                                    id="canthijos"
                                    name="canthijos"
                                    className='fullWidth'
                                    // className={classes.textField}
                                    onChange={this.handleInput}
                                    value={ this.state.canthijos != null ? ""+this.state.canthijos : ''}
                                    //error={!!this.state.errors.canthijos}
                                    helperText={<HelperText>{this.state.errors.canthijos ? this.state.errors.canthijos : ''}</HelperText>}
                                >
                                    <Option value=''></Option>
                                    <Option value='0'>0</Option>
                                    <Option value='1'>1</Option>
                                    <Option value='2'>2</Option>
                                    <Option value='3'>3</Option>
                                    <Option value='4'>4</Option>
                                    <Option value='5'>5</Option>
                                    <Option value='6'>6</Option>
                                    <Option value='7'>7</Option>
                                    <Option value='8'>8</Option>
                                    <Option value='9'>9</Option>
                                    <Option value='10'>10</Option>
                                </Select>
                                <Select
                                    required
                                    errormsg="Debe ingresar un Departamento válido."
                                    label='Departamento'
                                    id="DEPARTAMENTO_ID"
                                    name="DEPARTAMENTO_ID"
                                    className='fullWidth'
                                    // className={classes.textField}
                                    onChange={this.handleDirInput}
                                    value={ this.state.region1 != null && this.state.region1 !== '' ? (parseInt(this.state.region1) < 20100 ? this.state.region1 : '20011' ): ''}
                                    //error={!!this.state.errors.DEPARTAMENTO}
                                    helperText={<HelperText>{this.state.errors.REGION1 ? this.state.errors.REGION1 : ''}</HelperText>}
                                >
                                    <Option value=''></Option>
                                    <Option value='20012'>Huehuetenango</Option>
                                    <Option value='20013'>Izabal</Option>
                                    <Option value='20014'>Jalapa</Option>
                                    <Option value='20015'>Jutiapa</Option>
                                    <Option value='20016'>Petén</Option>
                                    <Option value='20023'>Suchitepéquez</Option>
                                    <Option value='20024'>Totonicapán</Option>
                                    <Option value='20025'>Zacapa</Option>
                                    <Option value='20017'>Quetzaltenango</Option>
                                    <Option value='20018'>Retalhuleu</Option>
                                    <Option value='20019'>Sacatepéquez</Option>
                                    <Option value='20020'>San Marcos</Option>
                                    <Option value='20021'>Santa Rosa</Option>
                                    <Option value='20022'>Sololá</Option>
                                    <Option value='20004'>Alta Verapaz</Option>
                                    <Option value='20005'>Baja Verapaz</Option>
                                    <Option value='20006'>Chimaltenango</Option>
                                    <Option value='20007'>Chiquimula</Option>
                                    <Option value='20008'>El Progreso</Option>
                                    <Option value='20009'>El Quiché</Option>
                                    <Option value='20010'>Escuintla</Option>
                                    <Option value='20011'>Guatemala</Option>
                                </Select>
                                <Select
                                    disabled={!(parseInt(this.state.region1) === 20011 || parseInt(this.state.region1) > 20100) }
                                    label='Municipio'
                                    id="MUNICIPIO_ID"
                                    name="MUNICIPIO_ID"
                                    className='fullWidth'
                                    // className={classes.textField}
                                    onChange={this.handleDirInput}
                                    value={ this.state.region1 != null && parseInt(this.state.region1) > 20100 ?
                                            (parseInt(this.state.region1) < 20200 ?
                                                this.state.region1
                                                : '20119'
                                            )
                                          : ''}
                                    //error={!!this.state.errors.MUNICIPIO}
                                    helperText={<HelperText>{this.state.errors.region1 ? this.state.errors.region1 : ''}</HelperText>}
                                >
                                    <Option value=''></Option>
                                    <Option value='20119'>Guatemala</Option>
                                    <Option value='20120'>Santa Catarina Pinula</Option>
                                    <Option value='20121'>San José Pinula</Option>
                                    <Option value='20122'>San José Del Golfo</Option>
                                    <Option value='20123'>Palencia</Option>
                                    <Option value='20124'>Chinautla</Option>
                                    <Option value='20125'>San Pedro Ayampuc</Option>
                                    <Option value='20126'>Mixco</Option>
                                    <Option value='20127'>San Pedro Sacatepéquez</Option>
                                    <Option value='20128'>San Juan Sacatepéquez</Option>
                                    <Option value='20129'>San Raymundo</Option>
                                    <Option value='20130'>Chuarrancho</Option>
                                    <Option value='20131'>Fraijanes</Option>
                                    <Option value='20132'>Amatitlán</Option>
                                    <Option value='20133'>Villa Nueva</Option>
                                    <Option value='20134'>Villa Canales</Option>
                                    <Option value='20135'>San Miguel Petapa</Option>
                                </Select>
                                <Select
                                    disabled={!(parseInt(this.state.region1) === 20119 || parseInt(this.state.region1) > 20300) }
                                    label='Zona'
                                    id="ZONA_ID"
                                    name="ZONA_ID"
                                    className='fullWidth'
                                    // className={classes.textField}
                                    onChange={this.handleDirInput}
                                    value={ this.state.region1 != null &&  parseInt(this.state.region1) > 20300 ?
                                            this.state.region1
                                          : ''}
                                    //error={!!this.state.errors.ZONA}
                                    helperText={<HelperText>{this.state.errors.ZONA_ID ? this.state.errors.ZONA_ID : ''}</HelperText>}
                                >
                                    <Option value=''></Option>
                                    <Option value='20362'>Zona 1</Option>
                                    <Option value='20363'>Zona 2</Option>
                                    <Option value='20364'>Zona 3</Option>
                                    <Option value='20365'>Zona 4</Option>
                                    <Option value='20366'>Zona 5</Option>
                                    <Option value='20367'>Zona 6</Option>
                                    <Option value='20368'>Zona 7</Option>
                                    <Option value='20369'>Zona 8</Option>
                                    <Option value='20370'>Zona 9</Option>
                                    <Option value='20371'>Zona 10</Option>
                                    <Option value='20372'>Zona 11</Option>
                                    <Option value='20373'>Zona 12</Option>
                                    <Option value='20374'>Zona 13</Option>
                                    <Option value='20375'>Zona 14</Option>
                                    <Option value='20376'>Zona 15</Option>
                                    <Option value='20377'>Zona 16</Option>
                                    <Option value='20378'>Zona 17</Option>
                                    <Option value='20379'>Zona 18</Option>
                                    <Option value='20380'>Zona 19</Option>
                                    <Option value='20381'>Zona 21</Option>
                                </Select>
                                <TextField
                                    className='fullWidth'
                                    label='Dirección para entrega a Domicilio'
                                    helperText={<HelperText>{this.state.errors.direccion1 ? this.state.errors.direccion1 : ''}</HelperText>}
                                ><Input
                                    id="direccion1"
                                    name="direccion1"
                                    // className={classes.textField}
                                    onChange={this.handleInput}
                                    value={ this.state.direccion1}
                                    //error={!!this.state.errors.DIRECCION}
                                />
                                </TextField>
                                <div className="mdc-form-field">
                                    <Checkbox
                                        required
                                        name="terminos"
                                        nativeControlId='my-checkbox'
                                        checked={this.state.checked}
                                        indeterminate={this.state.indeterminate}
                                        onChange={(e) => {
                                            this.setState({
                                            checked: e.target.checked,
                                            indeterminate: e.target.indeterminate})}
                                        }
                                    />
                                    <div className="check-label">
                                        <label htmlFor='my-checkbox'>Acepto los <span onClick={this.handleOpenModal} className="terminos"> terminos y condiciones</span></label>
                                    </div>
                                </div>
                                <div className='textRight'>
                                    <Button raised onClick={Object.keys(this.state.errors).length ? this.showErrors : this.handleSubmit}>
                                        Crear Cuenta
                                    </Button>
                                </div>
                            </div>
                        </SwipeableViews>
                    </TopAppBarFixedAdjust>
                </div>
                <Dialog
                    onClose={(action) => this.setState({openModal: false, action})}
                    open={this.state.openModal}>
                    <DialogTitle>Terminos y condiciones</DialogTitle>
                    <DialogContent>
                        <h3>GNC BENEfit</h3>
                        <p>Este documento describe los términos y condiciones generales (en lo sucesivo los “Términos y Condiciones”) aplicables al uso del programa de Lealtad GNC BENEfit (el “Programa” o “GNC BENEfit”) operado por SUPER VITAMINAS, SOCIEDAD ANONIMA, así como por sus filiales y subsidiarias (en lo sucesivo “GNC”), disponible para los clientes de GNC (en lo sucesivo el “Usuario”).</p>
                        <p>Estos Términos y Condiciones gobiernan la relación entre usted (el “Usuario”) como Usuario del Programa y GNC, como oferente del mismo. Por favor lea estos Términos y Condiciones cuidadosamente antes de usar este Programa. Su registro en el Programa, por el medio que sea, ya sea en las tiendas de GNC de Guatemala, en el sitio www.gnc.com.gt (“el Sitio”), o en la aplicación GNC BENEfit (en lo sucesivo la “Aplicación”), constituye su aceptación para estar regido conforme a estos Términos y Condiciones, incluyendo las modificaciones que GNC pueda realizar en cualquier momento. Los Términos y Condiciones aquí establecidos tienen un carácter vinculante y obligatorio para todo aquél que utilice el Programa, por lo que, si usted no acepta estos Términos y Condiciones, usted debe abstenerse de utilizar el Programa. Al utilizar el Programa usted manifiesta su consentimiento total con los Términos y Condiciones vigentes del Programa.</p>

                        <strong>1. TÉRMINOS GENERALES DEL PROGRAMA DE LEALTAD GNC BENEfit</strong>
                        <p>GNC BENEfit es un programa de recompensas administrado y operado por GNC que se ofrece de manera exclusiva a sus clientes que sean calificables, de acuerdo a lo establecido en el numeral 3 (tres) de estos Términos y Condiciones. A través del Programa, los clientes de GNC que así lo deseen se registran en el Programa, convirtiéndose con ello en Usuarios del Programa. Los Usuarios registrados en el Programa podrán recibir promociones especiales o según el programa que aplique recibir efectivo o cash (en lo sucesivo “cash”) por cada compra que realicen de productos que se vendan en las tiendas de GNC (en lo sucesivo los “Productos”), según el tipo de Programa del que forme parte el Usuario.</p>

                        <strong>2. TIPOS DE NÍVELES DEL PROGRAMA</strong>
                        <p>El Usuario podrá contar con dos tipos de Programas, los cuales son:<br/><br/>
                        (i)            GNC BENEfit.- Este tipo de programa es gratuito y es asignado automáticamente al Usuario al inscribirse al Programa de Lealtad y otorga acceso a promociones especiales, y;<br/>
                        (ii)           GNC BENEfit PRO.- En este tipo de programa el Usuario gozará de una mayor cantidad de beneficios. El Usuario podrá contar con este tipo de Programa al pagar un precio de CIENTO VEINTINUEVE QUETZALES CON NOVENTA Y NUEVE CENTAVOS (Q. 129.99). El plazo de vigencia en que los Usuarios podrán gozar de este programa es de 1 (un) año contado de la fecha en que se les haya otorgado el mismo. Este programa tendrá como beneficios el acumular TRES QUETZALES (Q. 3.00) de cash por cada CIEN QUETZALES Q.100.00 de compra que el Usuario realice en las tiendas en la República de Guatemala operadas por GNC (en los sucesivo las “Sucursales”), o por medio del Sitio web, exclusivamente y las registre a la cuenta a su nombre, así como doble acumulación de cash en la primera compra, en promociones especiales y en una compra realizada en el mes del cumpleaños del usuario. Se podrá pagar hasta un cincuenta por ciento (50%) del valor de la factura en cash.</p>
                        <p>No aplicarán a los Programas, las compras de Productos en tiendas de la marca “GNC” ubicadas fuera de la República de Guatemala. Así mismo, las compras de Productos realizadas en la República de Guatemala, únicamente podrán acumular beneficios para el Programa que aplique, por lo cual no se podrán acumular beneficios en algún otro programa de recompensas de la marca “GNC”, si los hubiere.</p>

                        <strong>3. REGISTRO EN EL PROGRAMA DE LEALTAD</strong>
                        <p>Para poder registrarse en el Programa de Lealtad, el Usuario según el tipo de programa deberá registrarse de la siguiente manera y otorgar los datos siguientes:<br/><br/>
                        a.    GNC BENEfit.-  en este programa se puede inscribir en las sucursales o por medio de la aplicación.  <br/>
                        b.    GNC BENEfit PRO.- en este programa se puede inscribir únicamente en las sucursales, en las cuales deberá realizar el pago del precio del programa.</p>
                        <p>En ambos programas para poder ser registrado el Usuario llenará un formulario de solicitud que deberá al menos contener los siguientes datos:<br/><br/>
                        Nombre y apellido<br/>
                        Numero de Documento Personal de Identificación –DPI- <br/>
                        Fecha de Nacimiento <br/>
                        Genero <br/>
                        Teléfono <br/>
                        Departamento de Residencia <br/>
                        Correo electrónico <br/>
                        Así mismo, el Usuario OPCIONALMENTE podrá compartir los siguientes datos:<br/><br/>

                        Dirección de residencia<br/>
                        Hijos<br/>
                        Deportes que practica<br/>
                        Otros que se indiquen en el formulario.<br/>
                        Posterior al registro antes mencionado, el Usuario recibirá en el correo electrónico las instrucciones para descargar la aplicación.  Si el programa es el GNC BENEfit Pro al momento de pagar su programa en la sucursal podrá validar sus datos y crear su usuario directamente en la aplicación.  Una vez activado el usuario GNC BENEfit Pro empezará a recibir Q. 3.00 de cash por cada Q. 100.00 de compra.</p>

                        <strong>4. REQUISITOS PARA FORMAR PARTE DEL PROGRAMA</strong>
                        <p>Los requisitos para que el Usuario pueda formar parte del Programa y acumular cash, son los siguientes:<br/><br/>
                        i) Ser mayor de edad. Para acreditar su mayoría de edad, el Usuario deberá presentar su Documento Personal de Identificación –DPI- o su pasaporte si es extranjero, la cual podrá ser solicitada por GNC en cualquier momento cuando a su entera discreción lo considere necesario y en caso de que el Usuario no acredite la mayoría de edad, GNC podrá cancelar su cuenta o negar su registro en el Programa.<br/>
                        ii) Conocer y aceptar en su totalidad los Términos y Condiciones.<br/>
                        iii) No ser cliente mayorista de GNC. Y <br/>
                        iv)  Completar el proceso de registro al Programa.</p>

                        <strong>5. ACUMULACIÓN DE CASH</strong>
                        <p>Los beneficios para los Usuarios de cada Programa serán según lo establecido en el punto 2 según el nivel del mismo.<br/><br/>
                        Además podrán haber otros beneficios como:<br/><br/>
                        Promociones Exclusivas: Días de promociones con doble o triple acumulación de cash por cada compra de Productos. Los días de promociones se darán a conocer a través de correo electrónico, redes sociales u otros medios. Dicho beneficio podrá variar en cualquier momento, quedando a entera discreción de GNC el beneficio otorgado.<br/><br/>
                        Promoción de cumpleaños: Si el Usuario registra su fecha de nacimiento obtendrá como promoción doble acumulación de cash en una compra en el mes de su cumpleaños. Dicha promoción podrá variar en cualquier momento, quedando a entera discreción de GNC el beneficio otorgado. GNC notificará al Usuario por medio del correo electrónico el beneficio obtenido.<br/><br/>
                        En caso de que el Usuario se registre en el Programa durante el mes de su cumpleaños, podrá obtener este beneficio hasta el siguiente año, es decir, no lo obtendrá durante el mes de registro, esto es debido a que los Usuarios se configuran el primer día del mes que se encuentre en curso.<br/><br/>
                        Bono de bienvenida: En el momento en el que el Usuario se registra al Programa, obtiene un bono de bienvenida que es doble acumulación de cash en la primera compra luego de inscribirse en el programa GNC BENEfit Pro. GNC notificará por correo electrónico el beneficio obtenido. Dicho bono podrá variar en cualquier momento, quedando a entera discreción de GNC el beneficio otorgado.</p>

                        <strong>6. CANJE DE CASH:</strong>
                        <p>El Usuario puede canjear su cash acumulado hasta un cincuenta por ciento (50%) del valor de la factura del producto a comprar, del siguiente modo:<br/><br/>
                        Para canjear en Sucursal:<br/><br/>
                        1. El Usuario deberá acudir a Sucursal<br/>
                        2. El Usuario deberá presentar en caja con los Productos seleccionados para su compra.<br/>
                        3. Previo a realizar el pago, el Usuario deberá proporcionar al asesor de Sucursal el correo electrónico y/o nombre ligado a su cuenta GNC BENEfit e indicar el cash que desea canjear.<br/><br/>
                        En ningún caso el Usuario podrá solicitar canjear su cash por dinero en efectivo. GNC se reserva el derecho de limitar los Productos participantes para canje en el Programa, así como excluir determinados Productos del mismo.</p>

                        <strong>7. CONSULTA DE CASH E INFORMACIÓN DE LA CUENTA DEL USUARIO</strong>
                        <p>El Usuario puede consultar el saldo de su cash, acudiendo a una Sucursal, por medio de la Aplicación, ingresando a su cuenta por medio del Sitio web, o  llamando al teléfono: 24221300  Extensión 1153, de Lunes a Viernes de 8:00 a.m. a 5:00 p.m., o solicitándolo en nuestro correo electrónico: servicioalcliente@gnc.com.gt, en el cual recibirá una respuesta en aproximadamente dos (2) días hábiles.</p>
                        <p>Así mismo, el Usuario podrá consultar su información en la Aplicación, donde podrá visualizar los siguientes datos:<br/><br/>
                        • Fecha de compras.<br/>
                        • Acumulación de cash en cada compra.<br/>
                        • Disponibilidad de cash pendiente de cambiar.<br/>
                        • Canje de cash (por fecha), historial de acumulación/redención del último año, últimos meses y último mes.</p>

                        <strong>8. USO DEL CORREO ELECTRÓNICO</strong>
                        <p>GNC podrá utilizar el correo electrónico con el que el Usuario se registre en el Programa, para envío de información relativa a dicho Programa, los mensajes que podrán ser enviados serán entre otros, los siguientes: bienvenida al Programa, saldo de cash, estado de cuenta, felicitaciones de cumpleaños, promociones con cash.</p>
                        <p>El Usuario podrá solicitar en cualquier momento ser dado de baja de la lista de correos de GNC, haciendo clic en la opción de cancelar suscripción que se encuentra ubicada dentro del apartado de perfil dentro de la Aplicación.</p>

                        <strong>9. VIGENCIA</strong>
                        <p>La participación en el Programa GNC BENEfit no tiene vigencia.<br/>
                        La participación en el Programa BENEfit PRO tiene vigencia de DOCE MESES. No obstante lo anterior, GNC podrá cancelar o dar por terminado total o parcialmente el Programa en cualquier momento.<br/>
                        El cash acumulado vence en el plazo de DOCE MESES a partir de la fecha de la compra del Usuario. El cash vencido en dicho plazo, no podrá ser reintegrado bajo ningún supuesto, por lo cual al momento de su vencimiento serán eliminados permanentemente de la cuenta del Usuario. Y el programa debe estar vigente y con el pago al día para que el usuario pueda hacer uso del cash disponible.<br/>
                        La sorpresa de cumpleaños solo es válida durante el mes del cumpleaños del Usuario.<br/>
                        El Bono de bienvenida tendrá una vigencia establecida en los comunicados emitidos para tal efecto por GNC. En todo caso, cuando dichos comunicados no establezcan una vigencia determinada, se entenderá que su vigencia es de 1 (un) año a partir de otorgados.</p>

                        <strong>10. CANCELACIÓN DE LA CUENTA </strong>
                        <p>El Usuario puede cancelar en cualquier momento su cuenta llamando al teléfono: 24221300, de Lunes a Viernes de 8:00 a.m. a 5:00 p.m., o solicitándolo en nuestro correo electrónico: servicioalcliente@gnc.com.gt, en el cual recibirá una respuesta en aproximadamente 3 (tres) días hábiles. Los puntos que tenga disponibles al momento de la cancelación serán cancelados inmediata e irreversiblemente. GNC se reserva el derecho de suspender o cancelar la cuenta  de cualquiera de los Usuarios, en cualquier momento, por cualquier razón o sin motivo. Pudiendo ser dicha cancelación, por el mal uso de la cuenta por parte del Usuario o por el incumplimiento a los Términos y Condiciones del Programa o del Sitio.</p>
                        <p>En caso de fallecimiento o incapacidad del Usuario, GNC se reserva el derecho de cancelar y, por lo tanto, anular todos cash que contenga la cuenta del Usuario en cuestión.</p>
                        <p>En el caso de la terminación del Programa, todo el cash acumulado por el Usuario será anulado por GNC, por lo que éste último no tendrá ninguna responsabilidad con respecto al cash acumulado y no redimido o utilizado por el Usuario en caso de terminar parcial o totalmente el Programa.</p>
                        <p>GNC se reserva el derecho de cancelar la cuenta a cualquier Usuario que a juicio de GNC haya realizado fraude, abuso, violación o cualquier uso indebido de su cash y en general, del Programa. Al ser descalificado, el Usuario pierde todos sus derechos de miembro del Programa, incluyendo todo su cash acumulado, privilegios y beneficios, estando sujeto a acciones administrativas y/o legales por parte de GNC, según sea el caso. En el supuesto de que el Usuario sea empleado de GNC, adicional a lo anterior estaría sujeto a una posible baja laboral.</p>

                        <strong>11. LIMITACIONES DE LA RESPONSABILIDAD.</strong>
                        <p>NI GNC, NI SUS EMPRESAS CONTROLADORAS, CONTROLADAS, FILIALES O SUBSIDIARIAS, ASÍ COMO TAMPOCO SUS PARTES RELACIONADAS, ACCIONISTAS, REPRESENTANTES LEGALES, DIRECTIVOS, GERENTES Y/O DEMÁS EMPLEADOS ASUMEN CUALQUIER TIPO DE RESPONSABILIDAD, NI SERÁN OBLIGADOS, POR CUALQUIER DAÑO OCASIONADO POR EL USO DEL PROGRAMA. EN NINGÚN MOMENTO GNC, NI CUALQUIERA DE SUS REPRESENTANTES LEGALES, GERENTES, DIRECTORES, EMPLEADOS, ACCIONISTAS, AFILIADOS, SUBSIDIARIAS, EMPRESAS CONTROLADORAS, CONTROLADAS, PARTES RELACIONADAS, AGENTES, RESPECTIVOS SUCESORES NI CUALQUIER OTRA PARTE IMPLICADA EN LA CREACIÓN, LA PRODUCCIÓN, LA TRANSMISIÓN DEL PROGRAMA, SERÁN RESPONSABLES POR LOS DAÑOS DIRECTOS, INDIRECTOS, ESPECIALES, PUNITIVOS, FORTUITOS O CONSECUENTES (INCLUYENDO, SIN LIMITACIÓN, AQUELLAS QUE RESULTEN DE BENEFICIOS PERDIDOS, DATOS PERDIDOS O INTERRUPCIÓN DEL NEGOCIO) RESULTANTES DEL USO, DE LA INHABILIDAD DE UTILIZAR , O DE LOS RESULTADOS DEL USO DE ESTE PROGRAMA. EN CASO DE CUALQUIER PROBLEMA CON ESTE PROGRAMA O CUALQUIER CONTENIDO, LA ÚNICA SOLUCIÓN ES DEJAR DE USAR ESTE PROGRAMA.<br/>
                        EL USUARIO CONFIRMA QUE TODA LA INFORMACIÓN PROPORCIONADA PARA SU REGISTRO EN EL PROGRAMA ES VERDADERA. POR LO CUAL NI GNC, NI SUS ENTIDADES CONTROLADORAS, CONTROLADAS, FILIALES O SUBSIDIARIAS, ASÍ COMO TAMPOCO SUS PARTES RELACIONADAS, ACCIONISTAS, REPRESENTANTES LEGALES, DIRECTIVOS, GERENTES Y/O DEMÁS EMPLEADOS ASUMEN CUALQUIER TIPO DE RESPONSABILIDAD, NI SERÁN OBLIGADOS, POR CUALQUIER DAÑO OCASIONADO POR EL USO DE DICHA INFORMACIÓN.<br/>
                        LA INFORMACIÓN Y DATOS PERSONALES QUE GNC RECABE PARA CUMPLIR CON LOS FINES DEL PROGRAMA SERÁ MANEJADA Y RESGUARDADA CON APEGO AL AVISO DE PRIVACIDAD DE GNC, EL CUAL SE ENCUENTRA PUBLICADO EN EL SITIO, LA APLICACIÓN Y EN LA LEY.<br/>
                        LA RESPONSABILIDAD DE GNC ESTARÁ LIMITADA EN TODO MOMENTO Y SIN EXCEPCIÓN AL IMPORTE TOTAL DE CONSUMO REGISTRADO POR EL USUARIO EN EL PROGRAMA DESDE SU INGRESO EN EL MISMO Y HASTA LA BAJA O CANCELACIÓN DE SU CUENTA.</p>

                        <strong>12. DISPOSICIÓNES GENERALES</strong>
                        <p>El cash bajo ningún motivo podrá ser transferible o utilizado de algún modo distinto al pactado en los presentes Términos y Condiciones.</p>
                        <p>El cash acumulado se verá reflejado en el sistema al día siguiente de la compra de Productos.<br/>
                        En caso de que sea requerido, se podrá revisar la compensación o aclaración de cash, debiendo el Usuario presentar para su procedencia, el ticket de compra o la confirmación de compra recibida por correo electrónico según corresponda. La decisión de la acumulación de dicho cash por compensación o aclaración quedará a entera discreción de Gerencia Comercial o Mercado de GNC.<br/>
                        Cuando el Usuario haga una devolución de Productos, se descontará el cash que haya acumulado en esa compra.<br/>
                        Si en la compra del o los Producto (s) que posteriormente fueron devueltos, el Usuario pagó realizando canjeo de cash, el cash que hayan sido canjeados en esa compra, serán bonificados a la cuenta del Usuario por el personal de la Sucursal, al momento de que el Usuario realice la devolución de dichos Productos. Después del cierre de la operación de devolución, no se podrá realizar bonificación de cash por el motivo indicado en el presente punto. GNC no será responsable por cash que no haya sido bonificados en los términos antes indicados.<br/>
                        En caso de devolución de producto, la totalidad del cash que haya sido canjeado por la compra del o los Productos devueltos no se reembolsara.<br/>
                        En el momento en que el Usuario utilice la cuenta desde el Sitio web o la Aplicación, estará sujeto además a los términos y condiciones establecidos para el Sitio web o Aplicación, según corresponda.<br/>
                        El cash bajo ningún supuesto serán canjeables por dinero en efectivo.<br/>
                        Solo se acumulara cash sobre el pago realizado en Moneda Nacional (quetzal); es decir el pago realizado con cash no acumula más cash.</p>

                        <strong>13. MODIFICACIONES A LOS TÉRMINOS Y CONDICIONES.</strong>
                        <p>Estos Términos y Condiciones se pueden revisar por GNC en cualquier momento y pueden también ser actualizados o cancelados en cualquier momento sin responsabilidad para GNC, en cuyo caso, GNC estará obligada a notificar al Usuario través de la propia Aplicación o el Sitio, sobre las actualizaciones o modificaciones que sufran estos Términos y Condiciones. La utilización del Programa por parte del Usuario significará la aceptación de este a los nuevos Términos y Condiciones. Si el Usuario no está de acuerdo con las modificaciones a los Términos y Condiciones, debe abstenerse de continuar utilizando el Programa.</p>

                        <strong>14. LEGISLACIÓN APLICABLE Y JURISDICCIÓN.</strong>
                        <p>Estos Términos y Condiciones reemplazan cualquier otro acuerdo respecto al Programa, entre el Usuario y GNC al grado necesario para resolver cualquier inconsistencia o ambigüedad entre ellas. Estos Términos y Condiciones serán gobernados e interpretados de acuerdo con las leyes de la República de Guatemala, por lo que se entiende que se ha renunciado a cualquier otra jurisdicción o competencia que pudiera resultar por cualquier principio de conflicto de leyes. Una versión impresa de estos Términos y Condiciones será admisible en los procedimientos judiciales y administrativos basados sobre estos Términos y Condiciones al mismo grado y conforme a las mismas condiciones que otros documentos y expedientes de negocio generados y mantenidos originalmente en forma impresa</p>
                        </DialogContent>
                    <DialogFooter>
                        <DialogButton action='dismiss'>Cerrar</DialogButton>
                    </DialogFooter>
                </Dialog>
            </MuiPickersUtilsProvider>
        );
    }
}
function mapStateToProps(state) {
    return state.navigationReducer;
}
function mapDispatchToProps(dispatch) {
    return {
        navigation: bindActionCreators(navigationActions, dispatch),
        user: bindActionCreators(userActions, dispatch),
        alertActions: bindActionCreators(alertActions, dispatch)
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(Register);