import React, { Component } from 'react';
import {bindActionCreators} from "redux";
import { connect } from "react-redux";
import {navigationActions} from "../actions/navigationActions";
import carnet from '../assets/carnet.png';
import carnetpro from '../assets/carnetpro.png';
// import Barcode from 'react-barcode';

function mapDispatchToProps(dispatch) {
    return {
        navigation: bindActionCreators(navigationActions, dispatch),
    };
}

class Barcodes extends Component{
    state = {
        menuIndex: 5,
        appTitle: 'Mi BENEfit ID'
    };
    constructor(props) {
        super(props);
        this.props.navigation.setAppTitle(this.state.appTitle);
        this.props.navigation.setMenuIndex(this.state.menuIndex);
    }

    render() {
        console.log("this.props.userData.VENCIMIENTO");
        console.log(this.props.userData);
        console.log(this.props.userData.VIGENCIA);
        let date = new Date(this.props.userData.VIGENCIA);
        let vigencia = date.getDate()+'/'+(date.getMonth()+1)+'/'+date.getFullYear();
        date = new Date(this.props.userData.FECHA_NACIMIENTO);
        let nac = date.getDate()+'/'+(date.getMonth()+1)+'/'+date.getFullYear();
        let bg = (this.props.userData.TIER==='BENEfit PRO')?'barcodeCard bgPro':'barcodeCard bgFree';
        let carnetImg = (this.props.userData.TIER==='BENEfit PRO')?carnetpro:carnet;
        return (
            <div className='mainContainer'>
                <div className={bg}>
                    <img src={carnetImg} alt='gnc-cash' width='80%' height='inherit' className='bcImg' />
                    <div className='bcContent'>
                        <strong className='bigText'>{this.props.userData.NOMBRE_COMPLETO }</strong><br/>
                        <strong>{nac}</strong>
                        <br/><br/>
                        NÚMERO DE SOCIO<br/>
                        <strong>{this.props.userData.TARJETA}</strong><br/><br/>
                        VENCIMIENTO<br/>
                        <strong>{vigencia}</strong>
                    </div>
                    {/*<div className='bcBox textCenter'>*/}
                    {/*    <Barcode value={this.props.userData.TARJETA} />*/}
                    {/*</div>*/}
                </div>
            </div>
        );
    }
}
function mapStateToProps(state) {
    return state.userReducer;
}
export default connect(mapStateToProps, mapDispatchToProps)(Barcodes);