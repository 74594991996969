import {LoggedUser} from "../helpers/loggedUser";
import {authActionTypes} from "../constants/authActionTypes";

const initialState = {
    loading: true,
    loggedIn:false,
    user: {}
};

export default function authentication(state = initialState, action) {
    let formatted_user = LoggedUser();
    switch (action.type) {
        case authActionTypes.CHECK_STATUS_REQUEST:
            if(formatted_user.identity !== undefined) {
                return Object.assign({}, state, {
                    loggedIn: true,
                    user: formatted_user
                })
            }
            return state;
        case authActionTypes.LOGIN_SUCCESS:
            return Object.assign({}, state, {
                loggedIn: true,
                user: formatted_user
            });
        case authActionTypes.AUTHENTICATED:
            return Object.assign({}, state, {
                loggedIn: true,
                user: formatted_user
            });
        case authActionTypes.LOGOUT:
            return Object.assign({}, state, {
                loggedIn: false,
            });
        default:
            return state
    }
}