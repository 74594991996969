import React, { Component } from 'react';
import {bindActionCreators} from "redux";
import { connect } from "react-redux";
import {navigationActions} from "../actions/navigationActions";
import {authActions} from "../actions/authActions";
import {alertActions} from "../actions/alertActions";
import {Redirect} from "react-router-dom";

import TopAppBar, {TopAppBarFixedAdjust, TopAppBarIcon, TopAppBarRow, TopAppBarSection, TopAppBarTitle} from '@material/react-top-app-bar';
import MaterialIcon from "@material/react-material-icon";
import Button from '@material/react-button';
import TextField, {HelperText, Input} from '@material/react-text-field';
import mygnclogo from '../assets/mygnclogo.png';

class Register extends Component{
    state = {
        appTitle: 'Recuperar Contraseña',
        slideIndex: 0,
        errors: {
            email: 'Debes completar este campo.',
        },
        email: '',
    };

    constructor(props) {
        super(props);
        this.props.navigation.setAppTitle(this.state.appTitle);
        this.props.navigation.setMenuIndex(this.state.menuIndex);
        this.goBack = this.goBack.bind(this);
    }

    goBack(){
        this.props.history.goBack();
    }

    handleEmailInput = event => {
        const { value } = event.target;
        let new_state;
        if(!value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
            let errors = Object.assign({},this.state.errors, {email:'Debes colocar un mail válido.'});
            new_state = Object.assign({}, this.state, {errors: errors} );
        } else {
            delete this.state.errors.email;
            new_state = Object.assign({}, this.state);
        }
        this.setState(new_state);
        this.setState({email:value});
        console.log(this.state);
    };

    handleSubmit = () => {
            this.props.auth.recoverPass(this.state.email);
    };

    showErrors = () => {
        this.props.alertActions.error("Debes completar con un mail válido!")
    };

    render() {
        if(this.props.redirectToLogin){
            return (
                <Redirect
                    to={{
                        pathname: "/login",
                    }}
                />
          );
        }
        return (
            <div>
                <TopAppBar dense={true}>
                    <TopAppBarRow>
                        <TopAppBarSection align='start'>
                            <TopAppBarIcon navIcon tabIndex={0}>
                                <MaterialIcon hasRipple icon='arrow_back' onClick={this.goBack}/>
                            </TopAppBarIcon>
                            <TopAppBarTitle><div dangerouslySetInnerHTML={{__html: this.state.appTitle}}/></TopAppBarTitle>
                        </TopAppBarSection>
                    </TopAppBarRow>
                </TopAppBar>
                <TopAppBarFixedAdjust>
                    <section className='header'>
                        <img alt='logo' className="gncLogo" src={mygnclogo}/>
                    </section>
                    <TextField
                        className='username'
                        label='Email'
                        helperText={<HelperText className="username">{this.state.errors.email ? this.state.errors.email : ''}</HelperText>}
                    ><Input
                        required
                        maxLength="55"
                        id="email"
                        name="email"
                        value={this.state.email}
                        onChange={this.handleEmailInput} />
                    </TextField>
                    <div className="button-container">
                        <Button raised onClick={Object.keys(this.state.errors).length ? this.showErrors : this.handleSubmit}>
                            Recuperar Contraseña
                        </Button>
                    </div>
                </TopAppBarFixedAdjust>
            </div>
        );
    }
}
function mapStateToProps(state) {
    return state.navigationReducer;
}
function mapDispatchToProps(dispatch) {
    return {
        navigation: bindActionCreators(navigationActions, dispatch),
        auth: bindActionCreators(authActions, dispatch),
        alertActions: bindActionCreators(alertActions, dispatch)
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(Register);