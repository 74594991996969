import React, { Component } from 'react';
import Card from '@material/react-card';
import Fab from '@material/react-fab';
//import MaterialIcon from '@material/react-material-icon';

class HomeProfileCard extends Component{
    render() {
        return (
            <Card className='homeProfileCard'>
                {/*<Fab className='profilePic' icon={<img alt='profile' src="https://i.pinimg.com/236x/3b/c7/23/3bc723c0857eca5f4aa6133ec35beb76--african-americans-running-techniques.jpg"/>}/>*/}
                <Fab className={this.props.fabBgColor} icon={<img src={this.props.img} alt='gnccash' className='imgprofile' />} disabled={true}/>
                <span className="hello"><span dangerouslySetInnerHTML={{__html: this.props.bigText}}/></span>
                {/*<span className="lastActivity">{this.props.lastActivity}</span>*/}
            </Card>
        );
    }
}

export default HomeProfileCard;