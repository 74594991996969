import { alertActions } from './alertActions';
import { userActions } from "./userActions";
import { authActionTypes } from '../constants/authActionTypes';
import { navigationActionTypes } from '../constants/navigationActionTypes';
import { userService } from '../services/userService';

export const authActions = {
    login,
    logout,
    recoverPass,
    checkStatus
};

function getErrorMessage(error) {
    let message = "";
    switch (error.status) {
        case 400:
        case 401:
            message = error.data;
        case 404:
            message = error.data;
            break;
        case 500:
        case 502:
            message = error.statusText;
            break;
        default:
            message = 'Ha habido un problema con la conexion, compruebe estar conectado.';
            break;
    }
    return message;
}

function login(email, password) {
    return dispatch => {
        dispatch(request());
        userService.login(email, password)
            .then(
                user => {
                    dispatch(authenticated());
                    dispatch(userActions.setLoggedUser(user));
                    dispatch(success(user));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(getErrorMessage(error)));
                }
            );
    };

    function authenticated() { return { type: authActionTypes.AUTHENTICATED} }
    function request()       { return { type: authActionTypes.LOGIN_REQUEST} }
    function success(user)   { return { type: authActionTypes.LOGIN_SUCCESS, user } }
    function failure(error)  { return { type: authActionTypes.LOGIN_FAILURE, error } }
}

function logout(props) {
    userService.logout();
    return { type: authActionTypes.LOGOUT };
}

function recoverPass(email) {
    return dispatch => {
        dispatch(request());

        userService.restorePass(email)
            .then(
                auth => {
                    if(!(auth instanceof Error) && !(auth === 'Network Error')){
                        dispatch(success());
                        dispatch(alertActions.success('¡Contraseña enviada por correo!'));
                        dispatch(redirect());
                    }else{
                        throw(auth);
                    }
                })
            .catch(
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(getErrorMessage(error)));
                });
    };

    function request()       { return { type: authActionTypes.RESTORE_REQUEST } }
    function success()   { return { type: authActionTypes.RESTORE_SUCCESS } }
    function redirect()   { return { type: navigationActionTypes.REDIRECT_LOGIN_TRUE } }
    function failure(error)  { return { type: authActionTypes.RESTORE_FAILURE, error } }
}

function checkStatus() {
    return dispatch => {
        dispatch(request());
        userService.info()
            .then(
                data => {
                    if(!data.status && !data.id) {
                        dispatch(complete_profile());
                    } else {
                        dispatch(authenticated());
                        dispatch(userActions.setLoggedUser(data))
                    }

                },
                error => dispatch(alertActions.error(error))
            );
    };

    function request()          { return { type: authActionTypes.CHECK_STATUS_REQUEST} }
    function complete_profile() { return { type: authActionTypes.COMPLETE_PROFILE_REQUEST} }
    function authenticated()    { return { type: authActionTypes.AUTHENTICATED} }
}
