import React, {Component} from 'react';
import { connect } from "react-redux";
import {bindActionCreators} from "redux";
import {authActions}  from "./actions/authActions";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
} from "react-router-dom";

import './App.scss';
import Alerts from "./components/Alert";
import AppDrawer from "./components/AppDrawer";
import Onboarding from "./views/Onboarding";
import Register from "./views/Register";
import Recovery from "./views/Recovery";
import Login from "./views/Login";


class App extends Component {
    constructor(props) {
        super(props);

        if(!this.props.loggedIn) {
            let user = JSON.parse(localStorage.getItem('user'));
            if (user) {
                this.props.auth.login(user.user.EMAIL, user.user.PASSWORD);
            }
        }
    }

    render() {
        return (
            <div>
                <Alerts />
                <Router>
                    <div>
                        <Route exact path='/' render={ props => <Redirect to={{ pathname: "/start", state: { from: props.location }}}/>} />
                        <PublicRoute logged={this.props.loggedIn} path="/start" component={Onboarding} />
                        <PublicRoute logged={this.props.loggedIn} path="/register" component={Register} />
                        <PublicRoute logged={this.props.loggedIn} path="/recovery" component={Recovery} />
                        <PublicRoute logged={this.props.loggedIn} path="/login" component={Login} />
                        <PrivateRoute logged={this.props.loggedIn} path="/app" component={AppDrawer} />
                    </div>
                </Router>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return state.authReducer;
}

function mapDispatchToProps(dispatch) {
    return {
        auth: bindActionCreators(authActions, dispatch),
    };
}
export default connect(mapStateToProps,mapDispatchToProps)(App);


function PublicRoute({ component: Component, logged: Logged, ...rest }) {
  return (
    <Route
      {...rest}
      render={props =>
        !Logged ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/app/home",
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
}

function PrivateRoute({ component: Component, logged: Logged, ...rest }) {
  return (
    <Route
      {...rest}
      render={props =>
        Logged ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
}

