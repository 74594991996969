import React, { Component } from 'react';
import {bindActionCreators} from "redux";
import { connect } from "react-redux";
import {navigationActions} from "../actions/navigationActions";
import { usePapaParse } from 'react-papaparse';

import HomeProfileCard from '../components/HomeProfileCard';
import HomeCashbackCard from "../components/HomeCashbackCard";
import DealsCard from "../components/DealsCard";
import Button from "@material/react-button";
import {userActions} from "../actions/userActions";
import gnccashwhite from '../assets/gnccashwhite.png';
import gnccashwhitepro from '../assets/gnccashwhitepro.png';


class Home extends Component{
    state = {
        menuIndex: 0,
        appTitle: 'GNC BENEfit',
        deals: []
    };
    constructor(props) {
        super(props);
        this.props.navigation.setMenuIndex(this.state.menuIndex);
    }
    componentDidMount() {
        this.props.user.getDataHome();
        const { readRemoteFile } = usePapaParse();

        readRemoteFile('https://docs.google.com/spreadsheets/d/e/2PACX-1vTAx9Px72ocXZRJ6LG8DWIzuv33q3sj16YFP_iac4zDTIbWdV5Vn4P5jpuh3Rn0ksMTa-yiT6_B7umb/pub?output=csv', {
            complete: (results) => {
                // console.log('---------------------------');
                // console.log('Results:', results.data);
                // console.log('---------------------------');
                this.setState({
                    deals: results.data
                })
            },
        });
    }
    goToBarcode = () => {
        this.props.history.push('/app/barcode');
    };
    goToHistory = () => {
        this.props.history.push('/app/history');
    };
    lastActivity = () => {
        if(this.props.cashbackData === undefined){
            return 'No tienes compras registradas';
        }else{
            return 'Tu última compra fue el '+this.props.cashbackData.lastPurchase.getDate()+'/'+this.props.cashbackData.lastPurchase.getMonth()+'/'+this.props.cashbackData.lastPurchase.getFullYear();
        }
    };
    cashbackAmount = () => {
       return parseFloat(!(this.props.cashbackData === undefined)?this.props.cashbackData.cashbackAmount:0).toFixed(2);
    };
    render() {
        const { deals } = this.state;
        const { userData } = this.props;
        let filteredGoogleData = deals.filter(function (el) {
                    return el[1] === userData.TIER ||
                        el[1] === 'ALL';
                });
        // console.log("filter:",filteredGoogleData);
        return (
            <div className='mainContainer'>
                <HomeProfileCard
                    img={(userData.TIER=='BENEfit PRO')?gnccashwhitepro:gnccashwhite}
                    bigText={'Hola, '+(this.props.userData.PRIMER_NOMBRE?this.props.userData.PRIMER_NOMBRE:'Amigo') +'!'} //+ (this.props.userData.TIER === 'BENEfit PRO' ? ' <strong>PRO</strong>':'')
                    // lastActivity={this.lastActivity()}
                    fabBgColor={(userData.TIER=='BENEfit PRO')?'profilePic bgPro':'profilePic bgFree'}
                />
                {(userData.TIER=='BENEfit PRO') ? <Button href="https://gnc.com.gt/pages/benefit-benefit-pro" target="_blank" rel="noopener noreferrer" raised className='secondary-filled-button fullWidth'>Ver Promociones PRO</Button> : ''}
                <Button onClick={this.goToBarcode} raised className='secondary-filled-button fullWidth margTop'><i className="mdc-fab__icon fas fa-barcode icon-barcode marg-right"></i> Mi BENEfit ID</Button>
                <HomeCashbackCard gotoHistory={() => this.goToHistory()} cashbackAmount={this.cashbackAmount()}/>
                {
                    filteredGoogleData.slice(0,2).map((obj,i) => {
                        return (
                            <DealsCard
                                key={i}
                                fecha={obj[0]}
                                fechaDesde={obj[2]}
                                fechaHasta={obj[3]}
                                titulo={obj[4]}
                                imgURL={obj[5]}
                                URL={obj[6]}
                                doubleCash={obj[7]}
                            />
                        )
                    })
                }
            </div>
        );
    }
}
function mapStateToProps(state) {
    return state.userReducer;
}
function mapDispatchToProps(dispatch) {
    return {
        navigation: bindActionCreators(navigationActions, dispatch),
        user: bindActionCreators(userActions, dispatch)
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(Home);

