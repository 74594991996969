export const userActionTypes = {
    SET_LOGGED_USER_REQUEST: 'USER_SET_LOGGED_USER_REQUEST',

    CHANGE_VALUE: 'CHANGE_VALUE',

    GET_DATA_REQUEST: 'GET_DATA_REQUEST',
    GET_DATA_SUCCESS: 'GET_DATA_SUCCESS',
    GET_DATA_FAILURE: 'GET_DATA_FAILURE',

    GET_CASHBACK_REQUEST: 'GET_CASHBACK_REQUEST',
    GET_CASHBACK_SUCCESS: 'GET_CASHBACK_SUCCESS',
    GET_CASHBACK_FAILURE: 'GET_CASHBACK_FAILURE',

    GETALL_REQUEST: 'USERS_GETALL_REQUEST',
    GETALL_SUCCESS: 'USERS_GETALL_SUCCESS',
    GETALL_FAILURE: 'USERS_GETALL_FAILURE',

    EDIT_REQUEST: 'USER_EDIT_REQUEST',
    EDIT_SUCCESS: 'USER_EDIT_SUCCESS',
    EDIT_FAILURE: 'USER_EDIT_FAILURE',

    REGISTER_REQUEST: 'USER_REGISTER_REQUEST',
    REGISTER_SUCCESS: 'USER_REGISTER_SUCCESS',
    REGISTER_FAILURE: 'USER_REGISTER_FAILURE',

    CHANGEPASS_REQUEST: 'CHANGEPASS_REQUEST',
    CHANGEPASS_SUCCESS: 'CHANGEPASS_SUCCESS',
    CHANGEPASS_FAILURE: 'CHANGEPASS_FAILURE',
};