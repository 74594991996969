import { alertActionTypes } from '../constants/alertActionTypes';

const initialState = {
    open: false,
    type: '',
    message: ''
};

export default function alertReducer(state = initialState, action) {
    switch (action.type) {
        case alertActionTypes.ALERT_SUCCESS:
            return Object.assign({}, state, {
                open: true,
                type: 'success',
                message: action.message
            });
        case alertActionTypes.ALERT_ERROR:
            return Object.assign({}, state, {
                open: true,
                type: 'error',
                message: action.message
            });
        case alertActionTypes.ALERT_WARNING:
            return Object.assign({}, state, {
                open: true,
                type: 'warning',
                message: action.message
            });
        case alertActionTypes.ALERT_INFO:
            return Object.assign({}, state, {
                open:true,
                type: 'info',
                message: action.message
            });
        case alertActionTypes.ALERT_CLEAR:
            return Object.assign({}, state, {
                open: false,
                type: state.type,
                message: null
            });
        default:
            return state
    }
}