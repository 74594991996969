import React, { Component } from 'react';
import {bindActionCreators} from "redux";
import { connect } from "react-redux";
import {navigationActions} from "../actions/navigationActions";
import { usePapaParse } from 'react-papaparse';
//import {dealsActions} from "../actions/dealsActions";

import TitleCard from '../components/TitleCard';
import DealsCard from "../components/DealsCard";

function mapDispatchToProps(dispatch) {
    return {
        navigation: bindActionCreators(navigationActions, dispatch),
        //deals: bindActionCreators(dealsActions, dispatch),

    };
}

class Deals extends Component{
    state = {
        menuIndex: 2,
        appTitle: 'Promociones Adicionales',
        deals: []
    };

    constructor(props) {
        super(props);
        this.props.navigation.setAppTitle(this.state.appTitle);
        this.props.navigation.setMenuIndex(this.state.menuIndex);
        //console.log(this.props.deals.getDeals());
    }

    componentDidMount() {
        const { readRemoteFile } = usePapaParse();

        readRemoteFile('https://docs.google.com/spreadsheets/d/e/2PACX-1vTopvOE1HG07pcVIr291euMCn1ekA5CkE7IAOHu5GCPwwRkKjxeyJD_axlBVV9bQB9_RLhZ8xDjgflf/pub?output=csv', {
            complete: (results) => {
                // console.log('---------------------------');
                // console.log('Results:', results.data);
                // console.log('---------------------------');
                this.setState({
                    deals: results.data
                })
            },
        });
    }

    render() {
        const { deals } = this.state;
        const { userData } = this.props;
        let filteredGoogleData = deals.filter(function (el) {
                    return el[1] === userData.TIER ||
                        el[1] === 'ALL';
                });
        return (
            <div className='mainContainer'>
                <TitleCard
                    icon='shop_two'
                    bigText='Últimas Promociones'
                />
                {
                    filteredGoogleData.map((obj,i) => {
                        return (
                            <DealsCard
                                key={i}
                                fecha={obj[0]}
                                fechaDesde={obj[2]}
                                fechaHasta={obj[3]}
                                titulo={obj[4]}
                                imgURL={obj[5]}
                                URL={obj[6]}
                                doubleCash={obj[7]}
                            />
                        )
                    })
                }
            </div>
        );
    }
}

function mapStateToProps(state) {
    return state.userReducer;
}
export default connect(mapStateToProps, mapDispatchToProps)(Deals);