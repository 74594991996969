import React, { Component } from 'react';
import Card from '@material/react-card';
import Fab from '@material/react-fab';
import MaterialIcon from '@material/react-material-icon';

class TitleCard extends Component{

    render() {
        return (
            <Card className='notifTitCard'>
                <Fab className='notifTitIcon' icon={this.props.icon?<MaterialIcon icon={this.props.icon}/>:<img src={this.props.img} alt='gnccash' className='imgfavtitle' />}/>
                <span className="bigText">{this.props.bigText}</span>
                {this.props.smallText ? <span className="smallText">{this.props.smallText}</span> : ''}
            </Card>
        );
    }
}

export default TitleCard;