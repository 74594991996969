import React, {Component} from 'react';
import {bindActionCreators} from "redux";
import connect from "react-redux/es/connect/connect";
import {alertActions} from "../actions/alertActions";
import {Snackbar} from '@material/react-snackbar';
import MaterialIcon from '@material/react-material-icon';

class Alerts extends Component {

    render() {
        return (
            <Snackbar
                open={this.props.open}
                message={<div dangerouslySetInnerHTML={{__html: this.props.message}}/>}
                variant={this.props.type}
                timeoutMs={6000}
                onClose={() => this.props.actions.clear()}
                actionText={<MaterialIcon icon='close'/>}
            />
        )
    }
}

function mapStateToProps(state) {
    return state.alertReducer;
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(alertActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Alerts);