export const authActionTypes = {

    REGISTER_REQUEST: 'REGISTER_REQUEST',
    REGISTER_SUCCESS: 'REGISTER_SUCCESS',
    REGISTER_FAILURE: 'REGISTER_FAILURE',

    LOGIN_REQUEST: 'LOGIN_REQUEST',
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGIN_FAILURE: 'LOGIN_FAILURE',

    CHECK_STATUS_REQUEST: 'CHECK_STATUS_REQUEST',
    COMPLETE_PROFILE_REQUEST: 'COMPLETE_PROFILE_REQUEST',
    AUTHENTICATED: 'AUTHENTICATED',

    RESTORE_REQUEST: 'RESTORE_REQUEST',
    RESTORE_SUCCESS: 'RESTORE_SUCCESS',
    RESTORE_FAILURE: 'RESTORE_FAILURE',

    LOGOUT: 'LOGOUT'
};