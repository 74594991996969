import React, { Component } from 'react';
import IconButton from '@material/react-icon-button';


class AppMenuFooter extends Component{
    state = {
        name: 'Alejandro'
    };

    render() {
        return (
            <div className="drawerButtonContainer">
                <IconButton isLink={true} href="tel:+50224221338" target="_blank" rel="noopener noreferrer">
                    <i className="fa fa-phone-alt"></i>
                </IconButton>
                <IconButton isLink={true} href="mailto:servicioalcliente@gnc.com.gt" target="_blank" rel="noopener noreferrer">
                    <i className="far fa-envelope-open"></i>
                </IconButton>
                <IconButton isLink={true} href="https://www.facebook.com/GNC.gt/" target="_blank" rel="noopener noreferrer">
                    <i className="fab fa-facebook"></i>
                </IconButton>
                <IconButton isLink={true} href="https://instagram.com/gncguatemala" target="_blank" rel="noopener noreferrer">
                    <i className="fab fa-instagram"></i>
                </IconButton>
            </div>
        );
    }
}

export default AppMenuFooter;