import React, { Component } from 'react';
import {bindActionCreators} from "redux";
import { connect } from "react-redux";
import {navigationActions} from "../actions/navigationActions";

import TitleCard from '../components/TitleCard';
import Card from '@material/react-card';
import {ChipSet, Chip} from '@material/react-chips';
// import MaterialIcon from '@material/react-material-icon';
import gnccashwhite from '../assets/gnccashwhite.png';
import Button from "@material/react-button";
import gnccashwhitepro from "../assets/gnccashwhitepro.png";

class History extends Component{
    state = {
        menuIndex: 4,
        appTitle: 'Historial',
        selectedChipIds: ['chip2'],
        filterLessOrEqualThan: 2,
    };
    constructor(props) {
        super(props);
        this.props.navigation.setAppTitle(this.state.appTitle);
        this.props.navigation.setMenuIndex(this.state.menuIndex);
    }
    handleFilter(selectedChipIds){
        this.setState({
            selectedChipIds: selectedChipIds,
            filterLessOrEqualThan: ((selectedChipIds[0]==='chip1')?1:(selectedChipIds[0]==='chip2')?2:3)
        });
    }
    cashbackAmount = () => {
       return parseFloat(!(this.props.cashbackData === undefined)?this.props.cashbackData.cashbackAmount:0).toFixed(2);
    };
    goToRedeem = () => {
        this.props.history.push('/app/redeem');
    };
    render() {
        const { userData } = this.props;
        return (
            <div className='mainContainer'>
                <TitleCard
                    img={(userData.TIER=='BENEfit PRO')?gnccashwhitepro:gnccashwhite}
                    bigText={'Q'+this.cashbackAmount()}
                    smallText='Cash disponible'
                />
                <Button onClick={this.goToRedeem} raised className='secondary-filled-button fullWidth margTop'>Mis Movimientos</Button>
                <Card className='margTop padding16'>
                    <ChipSet
                        choice
                        selectedChipIds={this.state.selectedChipIds}
                        handleSelect={(selectedChipIds) => this.handleFilter(selectedChipIds)}
                        className="collapsible open textCenter"
                    >
                        <Chip id={'chip1'} label='Último Mes' />
                        <Chip id={'chip2'} label='Último Año' />
                        <Chip id={'chip3'} label='Todas' />
                    </ChipSet>
                    <div className="mdc-data-table historyTable margTop">
                        <table className="mdc-data-table__table mdc-data-table-cell-padding(15px, 15px)" width="100%"
                               aria-label="Dessert calories">
                            <thead>
                            <tr className="mdc-data-table__header-row historyTableHeaders">
                                <th className="mdc-data-table__header-cell textCenter strong" role="columnheader" scope="col">FECHA{/*<MaterialIcon icon="calendar_today"/>*/}</th>
                                <th className="mdc-data-table__header-cell textCenter strong" role="columnheader" scope="col">VENCIMIENTO*{/*<MaterialIcon icon="calendar_today"/>*/}</th>
                                <th className="mdc-data-table__header-cell strong" role="columnheader" scope="col">ACUMULADO / REDIMIDO{/*<MaterialIcon icon="redeem"/>*/}</th>
                                <th className="mdc-data-table__header-cell textRight strong" role="columnheader" scope="col">DISPONIBLE{/*<img src={gnccash} alt='gnccash' height='20' width='inherit' />*/}</th>
                            </tr>
                            </thead>
                            <tbody className="mdc-data-table__content oddsBG">
                                {
                                    !(this.props.cashbackData === undefined)
                                        ?
                                        (!(this.props.cashbackData.cashback.filter((obj) => obj.HIST_FILTRO <= this.state.filterLessOrEqualThan).length === 0)
                                            ?
                                            this.props.cashbackData.cashback.map((obj,i) => {
                                                return (
                                                    obj.HIST_FILTRO > this.state.filterLessOrEqualThan ? <tr key={i}></tr> :
                                                    <tr className="mdc-data-table__row" key={i}>
                                                        <td className="mdc-data-table__cell textCenter">{obj.FECHA}</td>
                                                        <td className="mdc-data-table__cell textCenter">{obj.FECHA.substr(0,obj.FECHA.length - 1)+(parseInt(obj.FECHA.substr(-1))+1)}</td>
                                                        <td className="mdc-data-table__cell mdc-data-table__cell--numeric textCenter">
                                                            {((obj.TIPO_MOVIMIENTO === 'C')?'+ ': '- ')+obj.PUNTOS}
                                                        </td>
                                                        <td className="mdc-data-table__cell mdc-data-table__cell--numeric textRight strong">{obj.CB_ACUM}</td>
                                                    </tr>
                                                )
                                            })
                                            :
                                            <tr className="mdc-data-table__row">
                                                <td colSpan={3} className="mdc-data-table__cell textCenter">No hay compras registradas.</td>
                                            </tr>
                                        ):
                                        <tr className="mdc-data-table__row">
                                            <td colSpan={3} className="mdc-data-table__cell textCenter">No hay compras registradas.</td>
                                        </tr>
                                }
                            </tbody>
                        </table>
                        <div><small>*fecha vencimiento cash</small></div>
                    </div>
                </Card>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return state.userReducer;
}

function mapDispatchToProps(dispatch) {
    return {
        navigation: bindActionCreators(navigationActions, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(History);