import React, { Component } from 'react';
import {bindActionCreators} from "redux";
import { connect } from "react-redux";
import {navigationActions} from "../actions/navigationActions";
import { usePapaParse } from 'react-papaparse';
import Button from "@material/react-button";

function mapDispatchToProps(dispatch) {
    return {
        navigation: bindActionCreators(navigationActions, dispatch),
    };
}

class Plan extends Component{
    state = {
        menuIndex: 7,
        appTitle: 'Mi Programa',
        imgUrls: {
            0: {TIER:'BENEfit', ImgURL:''},
            1: {TIER:'BENEfit PRO', ImgURL:''}
        }
    };
    constructor(props) {
        super(props);
        this.props.navigation.setAppTitle(this.state.appTitle);
        this.props.navigation.setMenuIndex(this.state.menuIndex);
    }

    componentDidMount() {
        const { readRemoteFile } = usePapaParse();

        readRemoteFile('https://docs.google.com/spreadsheets/d/e/2PACX-1vQrs9jVoO-JlT92t9Jh5JcKgxthYMf_aCBbytqCztdoLEzZ9n7V8HsqylphjQPPrKKd-IZRtPYRLvvn/pub?output=csv', {
            complete: (results) => {
                // console.log('---------------------------');
                // console.log('Results:', results.data);
                // console.log('---------------------------');
                this.setState({
                    imgUrls: results.data
                })
            },
        });
    }

    render() {
        const { imgUrls } = this.state;
        const { userData } = this.props;
        let divImage = {
                backgroundImage : "url('')"
            };
        if(imgUrls.length > 0){
            let filteredImgUrls = imgUrls.filter(function (el) {
                    return el[0] === userData.TIER });
            console.log(filteredImgUrls);
            divImage = {
                backgroundImage : "url('"+ filteredImgUrls[0][1] +"')"
            };
        }
        let classnames = 'slide heightWithTopbar '+ ((userData.TIER=='BENEfit PRO')?'planbgpro':'planbg');
        let buttonpro = (userData.TIER!='BENEfit PRO')?'':<Button href="https://gnc.com.gt/pages/benefit-benefit-pro" target="_blank" rel="noopener noreferrer" raised className='secondary-filled-button fullWidth btnPlanPro'>CONOCER BENEfit PRO</Button>;

        return (
                <div className={classnames} style={divImage}>
                    {buttonpro}
                </div>
        );
    }
}
function mapStateToProps(state) {
    return state.userReducer;
}
export default connect(mapStateToProps, mapDispatchToProps)(Plan);