import React, { Component } from 'react';
import {bindActionCreators} from "redux";
import { connect } from "react-redux";
import {navigationActions} from "../actions/navigationActions";
import { GoogleMap, LoadScript, Marker, InfoWindow } from '@react-google-maps/api';
import { geolocated } from "react-geolocated";
import { usePapaParse } from 'react-papaparse';
import Fab from "@material/react-fab";
import MaterialIcon from "@material/react-material-icon";

function mapDispatchToProps(dispatch) {
    return {
        navigation: bindActionCreators(navigationActions, dispatch),
    };
}

class Stores extends Component{
    state = {
        menuIndex: 3,
        appTitle: 'Tiendas',
        stores: [],
        isOpen: false,
        keyOpen:null,
        mapLat: 14.6349,
        mapLng: -90.5069,
        mapCenterGeo: true
    };
    constructor(props) {
        super(props);
        this.props.navigation.setAppTitle(this.state.appTitle);
        this.props.navigation.setMenuIndex(this.state.menuIndex);
    }

    componentDidUpdate(oldProps) {
        if(oldProps.coords !== this.props.coords) {
            this.setState({
                mapLat: this.props.coords ? this.props.coords.latitude : 14.6349,
                mapLng: this.props.coords ? this.props.coords.longitude : -90.5069
            });
        }
    }

    componentDidMount() {
        const { readRemoteFile } = usePapaParse();

        readRemoteFile('https://docs.google.com/spreadsheets/d/e/2PACX-1vTo2A6T-xfNuqJhGkLElAYB_AXeQiJKPfC4DOnvJ6qrBacR0p0WhprqbJYpaMlBzpTze-ps3oOWPVLg/pub?output=csv', {
            complete: (results) => {
                // console.log('---------------------------');
                // console.log('Results:', results.data);
                // console.log('---------------------------');
                this.setState({
                    stores: results.data
                })
            },
        });
    }
    centerGeo() {
        if(this.props.coords) {
            this.setState({
                mapLat: this.props.coords.latitude ? this.props.coords.latitude : 14.6349,
                mapLng: this.props.coords.longitude ? this.props.coords.longitude : -90.5069,
                mapCenterGeo: true
            });
        }
    }
    dragGeo(){
        console.log(this.props);
        this.setState({
                mapCenterGeo: true
            });
    }
    handleToggleOpen = (i) => {
        this.setState({
            isOpen: true,
            keyOpen: i,
        });
    };
    handleToggleClose = (coord) => {
        this.setState({
            isOpen: false,
            keyOpen: null,
            mapLat: coord.lat,
            mapLng: coord.lng
        });
    };
    render() {
        const { stores } = this.state;
        return (
            <div className='googleMap'>
                <div className='gpsFabContainer'>
                    {!this.props.isGeolocationAvailable || !this.props.isGeolocationEnabled ?
                            <Fab icon={<MaterialIcon icon="gps_off"/>}/>
                        // : this.props.coords && this.props.coords.latitude === this.state.mapLat && this.props.coords.longitude === this.state.mapLng && this.state.mapCenterGeo ?
                        //         <Fab icon={<MaterialIcon icon="gps_fixed"/>} />
                            :
                                <Fab icon={<MaterialIcon icon="gps_fixed"/>} onClick={() => this.centerGeo()}/>
                    }
                </div>
                <LoadScript
                    id="script-loader"
                    googleMapsApiKey="AIzaSyDnWRhhzEj-O_f8H8SEF19th7oWDNJVEjI"
                >
                    <GoogleMap
                        defaultZoom={8}
                        className='googleMap'
                        id='example-map'
                        mapContainerStyle={{
                            height: "100%",
                            width: "100%"
                        }}
                        center={{
                            lat: this.state.mapLat ,
                            lng: this.state.mapLng
                        }}
                        zoom={13}
                    >
                        {
                            stores.map((obj, i) => {
                                return (
                                    <Marker
                                        key={i}
                                        title={obj[1]}
                                        position={{
                                            lat: parseFloat(obj[2]),
                                            lng: parseFloat(obj[3])
                                        }}
                                        onClick={() => this.handleToggleOpen(i)}
                                    >
                                        {this.state.isOpen && this.state.keyOpen === i &&
                                            <InfoWindow
                                                onCloseClick={() => this.handleToggleClose({lat: parseFloat(obj[2]), lng: parseFloat(obj[3])})}
                                                position={{
                                                    lat: parseFloat(obj[2]),
                                                    lng: parseFloat(obj[3])
                                                }}
                                            >
                                                <div>
                                                    <h3>{obj[1]}</h3>
                                                    <span>
                                                        Tel: {obj[4]}<br/>
                                                        Dir: {obj[5]}<br/>
                                                        <a href={obj[6]} target="_blank" rel="noopener noreferrer">Ir a la tienda</a>
                                                    </span>
                                                </div>
                                            </InfoWindow>
                                        }
                                    </Marker>
                                )
                            })
                        }
                    </GoogleMap>
                </LoadScript>
            </div>
        );
    }
}

export default geolocated({
    positionOptions: {
        enableHighAccuracy: false,
    },
    userDecisionTimeout: 5000,
})(connect(null, mapDispatchToProps)(Stores));