import { navigationActionTypes } from '../constants/navigationActionTypes';

export const navigationActions = {
    setAppTitle,
    setMenuIndex,
    redirectToLogin,
};

export function setAppTitle(newAppTitle) {
    return {type: navigationActionTypes.APP_TITLE, newAppTitle}
}

export function setMenuIndex(newMenuSelectedIndex) {
    return {type: navigationActionTypes.MENU_INDEX, newMenuSelectedIndex}
}

export function redirectToLogin(boolval) {
    if(boolval){
        return {type: navigationActionTypes.REDIRECT_LOGIN_TRUE}
    }else{
        return {type: navigationActionTypes.REDIRECT_LOGIN_FALSE}
    }
}
