import React, { Component } from 'react';
import {bindActionCreators} from "redux";
import { connect } from "react-redux";
import {navigationActions} from "../actions/navigationActions";
import {authActions} from "../actions/authActions";
import {Link} from "react-router-dom";

import Button from '@material/react-button';
import TextField, {HelperText, Input} from '@material/react-text-field';
import MaterialIcon from "@material/react-material-icon";
import mygnclogo from '../assets/mygnclogo.png';

class Login extends Component{
    constructor(props, context) {
        super(props, context);

        this.state = {
            email: '',
            password: '',
            submitted: false,
            visibility: false,
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handlePassVisibility = this.handlePassVisibility.bind(this);
    }
    componentDidMount() {
        this.props.navigation.redirectToLogin(false);
    }

    handleChange(e) {
        const { id, value } = e.target;
        this.setState({ [id]: value });
    }

    handleSubmit(e) {
        e.preventDefault();

        this.setState({ submitted: true });
        const { email, password } = this.state;
        if (email && password) {
            this.props.authActions.login(email, password)
        }
    }

    handlePassVisibility() {
        this.setState({visibility:!this.state.visibility});
    }

    render() {
        const { email, password, submitted } = this.state;
        return (
            <div>
                <section className='header'>
                    <img alt='logo' className="gncLogo" src={mygnclogo}/>
                </section>
                <form onSubmit={this.handleSubmit}>
                    <TextField
                        className='username'
                        label='Email/DPI/Pasaporte'
                        helperText={
                            <HelperText>
                                {submitted && !email && 'Debe ingresar un Email válido'}
                            </HelperText>
                        }
                    ><Input
                        id='email'
                        value={email}
                        type='text'
                        onChange={this.handleChange} />
                    </TextField>
                    <TextField
                        className='password'
                        label='Contraseña'
                        helperText={
                            <HelperText>
                                {submitted && !password && 'Debe ingresar una Contraseña'}
                            </HelperText>
                        }
                        onTrailingIconSelect={this.handlePassVisibility}
                        trailingIcon={<MaterialIcon role="button" icon={this.state.visibility?"visibility":"visibility_off"}/>}
                    ><Input
                        id='password'
                        value={password}
                        type={this.state.visibility?'text':'password'}
                        onChange={this.handleChange} />
                    </TextField>
                    <div className="button-container">
                        <Button raised type='submit'>Ingresar</Button>
                    </div>
                </form>
                <div className="link-container">
                    <Link to="/recovery" className="link">Olvidé mi contraseña</Link>
                    <Link to='/register' className="link">Crear Cuenta</Link>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return state.authReducer;
}

function mapDispatchToProps(dispatch) {
    return {
        navigation: bindActionCreators(navigationActions, dispatch),
        authActions: bindActionCreators(authActions, dispatch)
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(Login);